import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = { kabupaten_id:'',kecamatan_id:'',kecamatan:'', kabupaten_id_list: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Kecamatan";

        this.get_kabupaten_id_list();
					//##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        let form_data =
        {
          kabupaten_id:this.state.kabupaten_id,
 					kecamatan_id:this.state.kecamatan_id,
 					kecamatan:this.state.kecamatan,
 					//##field##:##value##//
        };

        let header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.post( api_url+'wilayah/kecamatan/add', form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
          if( data.status === 'success' ){

            notification.open({
              message: 'Berhasil',
              description:'Data telah disimpan ke database!',
            });

            this.props.history.push("/admin/data_wilayah/kecamatan/list");

          }else{
            notification.open({
                message: 'Error',
                description: data.data,
                icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
              });
          }

        })
      }


					get_kabupaten_id_list(){
		        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
		            response => {
		              this.setState({ kabupaten_id_list: response.data.data});
		          },
		          error => {
		            alert(error)
		          }

		        );
		      }

					select_option_for_kabupaten_id(){
		        if( this.state.kabupaten_id_list !== undefined && typeof this.state.kabupaten_id_list == "object"){
		          if( this.state.kabupaten_id_list.length > 0 ){
		            const res = (
		              <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
                  <option value="" key={ Math.random() }>Pilih</option>
		                {this.state.kabupaten_id_list.map(( value ) =>
		                  <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }


//##cst_function##/

    render() {
        console.log( this.state.kabupaten_id_list )
        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Data Wilayah</li>
                            <Link className="breadcrumb-item" to="/admin/data_wilayah/kecamatan/list">Kecamatan</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Kecamatan</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


					{/** Kabupaten **/}
					<div className="col-md-6">
            <div className="form-group">
              <label>Kabupaten<span className="text-danger">*</span></label>
								{ this.select_option_for_kabupaten_id() }
						</div>
          </div>


				{/** Kode Kecamatan **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Kode Kecamatan<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Kode Kecamatan" name="kecamatan_id" value={this.state.kecamatan_id} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Nama Kecamatan **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Nama Kecamatan<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Kecamatan" name="kecamatan" value={this.state.kecamatan} onChange={this.handleChange} required/>
                  </div>
                </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
