import React from 'react';
import axios from "axios";
import { message, notification, Skeleton } from 'antd';
import Moment from 'react-moment';
import 'moment/locale/id';
import {Bar} from 'react-chartjs-2';
import { Map, GeoJSON, TileLayer, Marker, Polygon, Popup } from 'react-leaflet';

const api_url = process.env.REACT_APP_API_URL;


export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total_users  : 0,
      total_kabupaten  : 0,
      total_kecamatan: 0,
      total_gampong: 0,
      geojson: null,
      lat: 5.5646816,
      lng: 95.3370112,
      zoom: 13,
      loading       : false,

    };

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Dashboard";
     this.get_data()
   }

   get_data(){
       var header = {
         headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
       };
       axios.get( api_url+'statistik/all/', header)
       .then(
         response => response.data,
         error => { alert( error ) }
       )
       .then((data) => {

         if( typeof data == 'object' ){
           if( data.status === 'success' ){
             this.setState({
               total_users: data.data.jumlah_users,
               total_kabupaten: data.data.jumlah_kabupaten,
               total_kecamatan: data.data.jumlah_kecamatan,
               total_gampong: data.data.jumlah_gampong
           }, () =>{
             // hide loader
             document.getElementById("loader").style.display = "none";

             if( this.state.file_geojson ){
               this.load_json_from_url(api_url+this.state.file_geojson)
             }

           });
           }else{
             message.error( data.data );
           }

         }
       })
     }


  render() {

    return (
    <div>
      <div className="row">

      <div className="col-12 grid-margin">

          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item">Dashboard</li>
              </ol>
          </nav>

      </div>



      <Skeleton loading={this.state.loading} active>
        <div className="col-md-3 grid-margin">
          <div className="row dashboard-card dashboard-card-primary">
            <div className="col-md-9 dashboard-card-content">
              <h5>Pengguna Aplikasi</h5>
              <h1 className="primary-color">{this.state.total_users}</h1>
            </div>
            <div className="col-md-3 dashboard-card-icon">
              <i className="fas fa-users"></i>
            </div>
          </div>
        </div>

        <div className="col-md-3 grid-margin">
          <div className="row dashboard-card dashboard-card-primary">
            <div className="col-md-9 dashboard-card-content">
              <h5>Kabupaten/Kota</h5>
              <h1 className="primary-color">{this.state.total_kabupaten}</h1>
            </div>
            <div className="col-md-3 dashboard-card-icon">
              <i class="fas fa-map-marked-alt"></i>
            </div>
          </div>
        </div>

        <div className="col-md-3 grid-margin">
          <div className="row dashboard-card dashboard-card-primary">
            <div className="col-md-9 dashboard-card-content">
              <h5>Kecamatan</h5>
              <h1 className="primary-color">{this.state.total_kecamatan}</h1>
            </div>
            <div className="col-md-3 dashboard-card-icon">
              <i class="fas fa-map-marker-alt"></i>
            </div>
          </div>
        </div>

        <div className="col-md-3 grid-margin">
          <div className="row dashboard-card dashboard-card-primary">
            <div className="col-md-9 dashboard-card-content">
              <h5>Gampong</h5>
              <h1 className="primary-color">{this.state.total_gampong}</h1>
            </div>
            <div className="col-md-3 dashboard-card-icon">
              <i class="fas fa-map-signs"></i>
            </div>
          </div>
        </div>

        <div className="col-md-12 grid-margin">
          <div className="row h-100 dashboard-card primary-bg">

            <div className="col-md-8 my-auto">
              <div className="d-flex">
                <div className="wrapper">
                  <h1 className="display-4 page-title white-color">Halo, {localStorage.getItem('nama')}</h1>
                  <h5 className="mb-5 font-weight-light text-white">Anda terakhir login pada <Moment format="dddd, DD MMMM YYYY HH:mm:ss" local>{localStorage.getItem('last_login')}</Moment></h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 my-auto">
              <div className="d-flex">
                <div className="wrapper">
                  <h4 className="mb-0 font-weight-light text-white"><Moment format="dddd" local></Moment></h4>
                  <h3 className="mb-0 font-weight-light text-white"><Moment format="DD MMMM YYYY" local></Moment></h3>
                  <h5 className="mb-0 font-weight-light text-white"><Moment format="HH:mm:ss" interval={1000} local></Moment></h5>
                </div>
              </div>
            </div>


          </div>
        </div>




      </Skeleton>




      </div>
    </div>
    );
  }

}
