import React from 'react';
import {Link} from "react-router-dom";
import Atable from "../../../lib/Atable";
import axios from "axios";
import {
    Skeleton,
    message,
    notification,
    Checkbox
} from 'antd';


const api_url = process.env.REACT_APP_API_URL;

export default class Add_level extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            group_name: '',
            description: '',
            loading: true
        };

        this.onChangeModule = this
            .onChangeModule
            .bind(this);
        this.onChangeSubModule = this
            .onChangeSubModule
            .bind(this);
        this.handleChange = this
            .handleChange
            .bind(this);
        this.handleSubmit = this
            .handleSubmit
            .bind(this);
    }

    componentDidMount() {
        document.title = process.env.REACT_APP_APP_NAME + "::Modul"
        this.load_data();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    onChangeModule(checkedValues, keyModule, idModule) {
        
        let datamodules = this.state.modules;

        if (checkedValues !== false) {

            datamodules[keyModule].module_id = idModule;
            datamodules[keyModule].enable = true;

        } else {

            datamodules[keyModule].module_id = '';
            datamodules[keyModule].enable = 'false';
        }

    }

    onChangeSubModule(checkedValues, keyModule, keySubmodule, submodulesId) {

        let datamodules = this.state.modules;

        if (checkedValues !== false) {
            let CRUD = [];
            let cekCRUD = ['read', 'create', 'update', 'delete'];
            for (var i = 0; i < checkedValues.length; i++) {
                CRUD[i] = checkedValues[i];
            }

            datamodules[keyModule].submodules[keySubmodule].submodules_id = submodulesId;
            cekCRUD.forEach((cekItem) => {

                if (CRUD.indexOf("" + cekItem) !== -1) {
                    if (cekItem === 'read') {
                        datamodules[keyModule].submodules[keySubmodule].read = true; 
                    } else if (cekItem === 'create') {
                        datamodules[keyModule].submodules[keySubmodule].create = true;
                    } else if (cekItem === 'update') {
                        datamodules[keyModule].submodules[keySubmodule].update = true;
                    } else if (cekItem === 'delete') {
                        datamodules[keyModule].submodules[keySubmodule].delete = true;
                    }

                } else {
                    if (cekItem === 'read') {
                        datamodules[keyModule].submodules[keySubmodule].read = false;
                    } else if (cekItem === 'create') {
                        datamodules[keyModule].submodules[keySubmodule].create = false;
                    } else if (cekItem === 'update') {
                        datamodules[keyModule].submodules[keySubmodule].update = false;
                    } else if (cekItem === 'delete') {
                        datamodules[keyModule].submodules[keySubmodule].delete = false;
                    }
                }
            });

        } else {
            
            datamodules[keyModule].submodules[keySubmodule].submodules_id = submodulesId;
            datamodules[keyModule].submodules[keySubmodule].create = false;
            datamodules[keyModule].submodules[keySubmodule].update = false;
            datamodules[keyModule].submodules[keySubmodule].delete = false;
            datamodules[keyModule].submodules[keySubmodule].read = false;

        }

    }

    handleSubmit(event) {

        event.preventDefault();

        message.loading('Loading...', 2.5);

        var modules = [];
        var subModules = [];
        var dataModule = this.state.modules;
        dataModule.forEach((item, index) => {

            if (item.module_id !== '') {

                subModules = [];
                var dataSubmodule = item.submodules;

                dataSubmodule.forEach((itemSub, indexSub) => {
                    if (itemSub.submodules_id !== '') {
                        subModules[indexSub] = {
                            submodules_id: itemSub.submodules_id,
                            create: itemSub.create,
                            read: itemSub.read,
                            update: itemSub.update,
                            delete: itemSub.delete
                        };
                    }
                })

                modules[index] = {
                    module_id: item.module_id,
                    enable: item.enable,
                    submodules: subModules
                };
            }
        });

        var form_data = {
            group_name: this.state.group_name,
            description: this.state.description,
            modules: modules
        };
        
        var header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) 
            }
        };

        axios
            .post(api_url + 'acl/groups/add_group', form_data, header)
            .then(response => response.data, error => {
                alert(error)
            })
            .then((data) => {
                if (data['status'] === 'success') {

                    notification.open({message: 'Berhasil', description: 'Data telah disimpan ke database!'});

                    this
                        .props
                        .history
                        .push("/admin/acl/level");

                } else {
                    message.error(data['data']);
                }

            })
    }

    load_data() {
        message.loading('Loading...', 3.5);
        var header = {
            headers: {
                'api_token': localStorage.getItem('token')
            }
        };

        axios
            .get(api_url + "acl/modules/get_modules", header)
            .then(response => {
                this.setState({table_data: response.data.data, total_record: response.data.recordsTotal, total_filter: response.data.recordsFiltered, loading: false});

                const statedata = this.state.table_data;
                let datamodules = [];
                var datasubmodule = [];
                let submodules = [];

                statedata.forEach((val, i) => {

                    submodules = [];
                    datasubmodule[i] = val.submodules;

                    datasubmodule[i].forEach((itemSub, indexSub) => {

                        submodules[indexSub] = {
                            submodules_id: '',
                            create: '',
                            read: '',
                            update: '',
                            delete: ''
                        };

                    })

                    datamodules[i] = {
                        module_id: '',
                        enable: '',
                        submodules: submodules
                    };

                });

                this.setState({modules: datamodules});

                message.destroy();
            });
    }

    name_submodule(dataSubmodule, keySubmodule) {

        const Submodule = props => (
            <td rowSpan='1' className="pt-2 pb-2">{props.val.submodule_name}</td>
        );

        if (dataSubmodule !== null) {
            return dataSubmodule.map((result, i) => {

                if (keySubmodule === i) {
                    return (<Submodule key={i} keys={i} val={result}/>);
                } else {
                    return null;
                }

            });

        } else {
            return null;
        }

    }

    checkbox_submodule(dataSubmodule, keyModul, keySubmodule) {

        const Sub = props => (
            <td rowSpan="1" className="pt-2 pb-2">
                <Checkbox.Group
                    options={[
                    {
                        label: 'Read',
                        value: 'read'
                    }, {
                        label: 'Create',
                        value: 'create'
                    }, {
                        label: 'Update',
                        value: 'update'
                    }, {
                        label: 'Delete',
                        value: 'delete'
                    }
                ]}
                    onChange={e => this.onChangeSubModule(e, keyModul, props.keys, props.val.submodule_id)}/>
            </td>
        );

        if (dataSubmodule !== null) {
            return dataSubmodule.map((result, i) => {
                if (keySubmodule === i) {
                    return (<Sub key={i} keys={i} val={result}/>);
                } else {
                    return null;
                }
            });

        } else {
            return null;
        }

    }

    head() {
        return ["Nama Modul", "Enable", "Nama Submodule", "Hak Akses"];
    }

    generate_rows() {

        const dataTable = this.state.table_data;

        const RowTable = props => props.val.submodules.length > 1
            ? props.subKeys === 0
                ? (
                    <tr >
                        <td rowSpan={props.val.submodules.length} className="pt-2 pb-2">
                            {props.val.module_name}
                        </td>

                        <td rowSpan={props.val.submodules.length} className="pt-2 pb-2">
                            <Checkbox.Group
                                options={[{
                                    label: 'Aktif',
                                    value: 'aktif'
                                }
                            ]}
                                onChange={e => this.onChangeModule(e, props.keys, props.val._id)}/>
                        </td>

                        {this.name_submodule(props.val.submodules, props.subKeys)}

                        {this.checkbox_submodule(props.val.submodules, props.keys, props.subKeys)}

                    </tr>
                )
                : (
                    <tr>

                        {this.name_submodule(props.val.submodules, props.subKeys)}

                        {this.checkbox_submodule(props.val.submodules, props.keys, props.subKeys)}

                    </tr>
                )
            : (
                <tr>
                    <td rowSpan="1" className="pt-3 pb-3">
                        {props.val.module_name}
                    </td>
                    <td rowSpan="1" className="pt-3 pb-3">
                        <Checkbox.Group
                            options={[{
                                label: 'Aktif',
                                value: 'aktif'
                            }
                        ]}
                            onChange={e => this.onChangeModule(e, props.keys, props.val._id)}/>
                    </td>

                    {this.name_submodule(props.val.submodules, props.subKeys)}

                    {this.checkbox_submodule(props.val.submodules, props.keys, props.subKeys)}

                </tr>
            );

        if (dataTable !== null && typeof dataTable == 'object' && typeof dataTable !== 'undefined') {
            return dataTable.map((result, i) => {
                return result
                    .submodules
                    .map((subResult, j) => {
                        return (<RowTable key={j} keys={i} subKeys={j} val={result}/>);
                    })

            });

        } else {
            return '';
        }

    }

    render() {
        return (
            <div>
                <div className="col-12 grid-margin">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <Link className="breadcrumb-item" to="/admin/acl/level">Level Pengguna</Link>
                            <li className="breadcrumb-item active">Tambah Level Pengguna</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Level Pengguna</h4>
                            <br/>

                            <Skeleton loading={this.state.loading} active>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">

                                            {/* Input groups name */}
                                            <div className="form-group">
                                                <label>Groups Name<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="group_name"
                                                    name="group_name"
                                                    value={this.state.group_name}
                                                    onChange={this.handleChange}
                                                    required/>
                                            </div>

                                        </div>
                                        <div className="col-md-6">

                                            {/* Input Description */}
                                            <div className="form-group">
                                                <label>Description<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Description"
                                                    name="description"
                                                    value={this.state.description}
                                                    onChange={this.handleChange}
                                                    required/>
                                            </div>

                                        </div>

                                        <div className="col-md-12">
                                            <label className='mb-3'>Pilih Hak Akses<span className="text-danger">*</span>
                                            </label>

                                            <Atable
                                                head={this.head()}
                                                data={this.generate_rows()}
                                                total_filter={this.state.total_filter}
                                                total_record={this.state.total_record}
                                                current_page={this.state.active_page}
                                                search={this.state.search}
                                                handleChange={this.handleChange}
                                                limit={this.state.limit}/>

                                        </div>

                                        <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                                    </div>
                                </form>
                            </Skeleton>
                        </div>

                    </div>
                </div>

            </div>
        );
    }

}
