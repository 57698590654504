import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import moment from 'moment';
import 'moment/locale/id';
import Auth from "../../../helper/Authentication";
import { Map as LeafletMap, GeoJSON, TileLayer, Marker, Popup } from 'react-leaflet';

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = {
            id: this.props.match.params.id,
            provinsi_id: null,
            kabupaten_id: null,
            kecamatan_id: null,
            nomor_izin:'',
            tanggal_berita_acara: new Date(),
            file_sertifikat:'',
            warna_palette:'#4A90E2',
            koordinat_opt: "1",
            entri_koordinat_error: '',
            list_koordinat: null,
            file_geojson: null,
            geojson: null

        };

    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Batas Kecamatan";
        this.get_data();

        // loader
        document.getElementById("loader").style.display = "inline";

    }

    get_data(){
        var header = {
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'batas_wilayah/batas_kecamatan/get/'+this.state.id, header)
            .then(
                response => response.data,
                error => { alert( error ) }
            )
            .then((data) => {

                if( typeof data == 'object' ){
                    if( data.status === 'success' ){
                        this.setState({
                            provinsi_id: data.data.provinsi[0].provinsi_id,
                            provinsi: data.data.provinsi[0].provinsi,
                            kabupaten_id: data.data.kabupaten[0].kabupaten_id,
                            kabupaten: data.data.kabupaten[0].kabupaten,
                            kecamatan_id: data.data.kecamatan[0].kecamatan_id,
                            kecamatan: data.data.kecamatan[0].kecamatan,
                            tanggal_berita_acara: data.data.tanggal_berita_acara,
                            file_sertifikat: data.data.file_sertifikat,
                            nomor_izin: data.data.nomor_izin,
                            nomor_pilar: data.data.nomor_pilar,
                            warna_palette: data.data.warna_palette,
                            file_geojson: data.data.file_geojson,
                            geojson: data.data.geojson
                        }, () =>{
                            // hide loader
                            document.getElementById("loader").style.display = "none";
                        });
                    }else{
                        message.error( data.data );
                    }

                }
            })
    }

    render() {
        // file sertifikat
        let file_sertifikat = [];
        if( this.state.file_sertifikat != null ){
            file_sertifikat.push(<a href={api_url+this.state.file_sertifikat} target="_BLANK" className="btn primary-button">Lihat File</a>)
        }else{
            file_sertifikat.push(<span className="text-danger">Tidak ada file</span>)
        }

        let file_geojson = null;
        if( this.state.file_geojson ){
            file_geojson = api_url+this.state.file_geojson;
        }
        let color_palette = this.state.warna_palette;

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Batas Wilayah</li>
                            <Link className="breadcrumb-item" to="/admin/batas_wilayah/batas_kecamatan/list">Batas Kecamatan</Link>
                            <li className="breadcrumb-item active">Detail</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Detail Batas Kecamatan</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>

                                <fieldset>
                                    <legend>Wilayah</legend>
                                    <div className="row">
                                        {/** Provinsi **/}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Provinsi<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" value={this.state.provinsi} disabled/>
                                            </div>
                                        </div>
                                        {/** Kabupaten **/}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" value={this.state.kabupaten} disabled/>
                                            </div>
                                        </div>

                                        {/** Kecamatan **/}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Kecamatan<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" value={this.state.kecamatan} disabled/>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>

                                <fieldset>
                                    <legend>Batasan</legend>
                                    <div className="row">
                                        {/** Nomor Izin **/}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Nomor Berita Acara</label>
                                                <input type="text" className="form-control" value={this.state.nomor_izin} disabled/>
                                            </div>
                                        </div>

                                        {/** Tanggal Izin **/}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tanggal Berita Acara</label>
                                                <input type="text" className="form-control" value={moment(this.state.tanggal_berita_acara).format("D MMMM YYYY")} disabled/>
                                            </div>
                                        </div>

                                        {/** Sertifikat **/}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>File PDF</label><br/>
                                                {file_sertifikat}
                                            </div>
                                        </div>

                                    </div>

                                </fieldset>

                                <fieldset>
                                    <legend>Koordinat</legend>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">

                                                {/** Nomor Pilar **/}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Nomor Pilar</label>
                                                        <input type="text" className="form-control" value={this.state.nomor_pilar} disabled/>
                                                    </div>
                                                </div>

                                            </div>

                                            <LeafletMap
                                                center={[5.5530926, 95.3043695]}
                                                zoom={10}
                                                maxZoom={100}
                                                attributionControl={true}
                                                zoomControl={true}
                                                doubleClickZoom={true}
                                                scrollWheelZoom={true}
                                                dragging={true}
                                                animate={true}
                                                easeLinearity={0.35}
                                            >
                                                <GeoJSON
                                                    data={file_geojson}
                                                    style={() => ({
                                                        color: '#4a83ec',
                                                        weight: 0.5,
                                                        fillColor: color_palette,
                                                        fillOpacity: 1,
                                                    })}
                                                />
                                                <TileLayer
                                                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                                                />
                                                <Marker position={[5.5530926, 95.3043695]}>
                                                    <Popup>

                                                    </Popup>
                                                </Marker>
                                            </LeafletMap>
                                        </div>
                                    </div>
                                </fieldset>

                                <div className="col-md-12">
                                    <div className="row">
                                        <Link className="btn secondary-button" to="/admin/batas_wilayah/batas_kecamatan/list">Kembali</Link>
                                    </div>

                                </div>

                            </form>

                        </div>
                    </div>

                </div>

            </div>
        );
    }

}
