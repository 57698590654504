import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { message, notification } from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group_id: this.props.match.params.id,
      group_name: '',
      group_desc: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Group";
     this.get_module();
  }

  get_module(){
    var header = {
      headers: { 'api_token': localStorage.getItem('token') }
    };
    axios.get( api_url+'acl/groups/get_groups/'+this.state.group_id, header)
    .then(
      response => response.data,
      error => { alert( error ) }
    )
    .then((data) => {

      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){
          const is_active = data.data.is_active === 1 ? "yes":"no";
          this.setState({
            module_name: data.data.module_name,
            module_url: data.data.module_url,
            module_icon: data.data.module_icon,
            is_active: is_active
          });
        }else{
          message.error( data['data'] );
        }

      }
    })
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });

  }

  handleSubmit(event) {
    event.preventDefault();

    message.loading('Loading...', 2.5);

    var is_active = (this.state.is_active === "yes")?1:0;
    var form_data =
    {
      module_name: this.state.module_name,
      module_url: this.state.module_url,
      module_icon: this.state.module_icon,
      is_active: is_active
    };

    var header = {
      headers: {
        'api_token': localStorage.getItem('token') }
    };

    axios.put( api_url+'acl/modules/edit_module/'+this.state.module_id, form_data, header
    ).then(response => response.data)
    .then((data) => {

      if( data['status'] === 'success' ){

        notification.open({
          message: 'Berhasil',
          description:'Data telah disimpan ke database!',
        });

        this.props.history.push("/admin/acl/modules");
        message.destroy();
      }else{
        message.error( data['data'] );
      }

    })
  }


  render() {
    return (
      <div>
        <div className="col-12 grid-margin">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item primary-color">ACL</li>
              <Link className="breadcrumb-item" to="/admin/acl/modules">Group</Link>
              <li className="breadcrumb-item active">Edit Group</li>
            </ol>
          </nav>
        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
            <h4 className="display-4 primary-color text-center">Edit Group</h4>
            <br />

            <form onSubmit={this.handleSubmit}>
              <div className="row">
                {/* Input Nama Group */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Modul<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Modul" name="module_name" value={this.state.group_name} onChange={this.handleChange} required/>
                  </div>
                </div>

                {/* Input Deskripsi */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Deskripsi</label>
                    <input type="text" className="form-control" placeholder="Deskripsi" name="deskripsi" value={this.state.group_desc} onChange={this.handleChange} required/>
                  </div>
                </div>

              </div>
              <button stype="submit" className="btn btn-full primary-button">Simpan</button>
            </form>

            </div>
          </div>
        </div>

      </div>
    );
  }

}
