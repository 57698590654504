/*
* Atable is a dynamic table component for react JS
* with server side data rendering
*
* author: Muhammad Ahsin
* email: ahsin@paralel.co.id
* v1.0
*/

import React from 'react'

class Atable extends React.Component {


  head(){
    // get and return table header
    if( Array.isArray( this.props.head ) ){
      const head_list = this.props.head.map((th) =>
        <th key={ Math.random() }>{th}</th>
      );
      return head_list;
    }
  }


  render() {
    // pagination data
    const total         = ( parseInt(this.props.total_record) > 0 )?parseInt(this.props.total_record):0;
    const total_filter  = ( parseInt(this.props.total_filter) > 0 )?parseInt(this.props.total_filter):0;
    const limit         = parseInt(this.props.limit);

    var page, pages;
    if( total > total_filter ){
      pages         = (total/limit);
      if( parseInt(this.props.current_page) == 0 ){
        page = 1;
      }else{
        page  = parseInt(this.props.current_page);
      }
    }else {
      pages         = 1;
      page          = 1;
    }

    const prev_button   = "<";
    const next_button   = ">";
    const first_button  = "<<";
    const last_button   = ">>";

    const current_page = [];
    const left  = [];
    const right = [];
    // const start = (page !== 0 && page !== 1)?page:1;

    for( var i = 1; i <= pages; i++ ){
      // current page number
      if( page === i ){
        // if( current_page.length == 0 ){
            current_page.push(<li className="page-item disabled" key={ Math.random() }><button name="active_page" value={i} className="page-link" onClick={this.props.handleChange} onContextMenu={this.props.handleChange}>{i}</button></li>);
        // }
      }

      // left number
      if( i > (page-4) && i < page ){
          left.push(<li className="page-item" key={ Math.random() }><button name="active_page" value={i} className="page-link" onClick={this.props.handleChange} onContextMenu={this.props.handleChange}>{i}</button></li>);
      }

      // right number
      if( i > page && i <= (page+3) ){
          right.push(<li className="page-item" key={ Math.random() }><button name="active_page" value={i} className="page-link" onClick={this.props.handleChange} onContextMenu={this.props.handleChange}>{i}</button></li>);
      }

    }

    // prev and first button
    var first, prev;
    if( page == 1 ){
      first = [<li className="page-item disabled" key={ Math.random() }><button className="page-link">{first_button}</button></li>];
      prev  = [<li className="page-item disabled" key={ Math.random() }><button className="page-link">{prev_button}</button></li>];
    }else{
      first = [<li className="page-item" key={ Math.random() }><button name="active_page" value={(1)} className="page-link" onClick={this.props.handleChange} onContextMenu={this.props.handleChange}>{first_button}</button></li>];
      prev  = [<li className="page-item" key={ Math.random() }><button name="active_page" value={(page-1)} className="page-link" onClick={this.props.handleChange} onContextMenu={this.props.handleChange}>{prev_button}</button></li>];
    }

    // next and last button
    var last, next;
    if( page >= total ){
      last = [<li className="page-item disabled" key={ Math.random() }><button className="page-link">{last_button}</button></li>];
      next = [<li className="page-item disabled" key={ Math.random() }><button className="page-link">{next_button}</button></li>];
    }else{
      last = [<li className="page-item" key={ Math.random() }><button name="active_page" value={ isNaN(total) ? total:0} className="page-link" onClick={this.props.handleChange} onContextMenu={this.props.handleChange}>{last_button}</button></li>];
      next = [<li className="page-item" key={ Math.random() }><button name="active_page" value={(page+1)} className="page-link" onClick={this.props.handleChange} onContextMenu={this.props.handleChange}>{next_button}</button></li>];
    }

    return(
    <div>
      <div className="row table-navbar">
        <div className="col-md-6">
          <label>Tampilkan &nbsp;</label>
          <select name="limit" value={this.props.limit} onChange={this.props.handleChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>

        <div className="col-md-6 float-md-right">
          <input type="text" name="search" className="form-control " autoComplete="off" placeholder="Search" value={this.props.search} onChange={this.props.handleChange} />
        </div>
      </div>

      <div className="table-responsive">
        <table className="atable table table-sm">
        <thead><tr>{this.head()}</tr></thead>
        <tbody>
            { this.props.data }
        </tbody>
        </table>
      </div>

        <div className="row">
          <div className="col-md-6">
            <p className="text-muted small-text atable-footer-info">
              Menampilkan { total_filter } baris di halaman {page} dari { total } baris data
            </p>
          </div>

          <div className="col-md-6">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                {first}
                {prev}
                {left}
                {current_page}
                {right}
                {next}
                {last}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    )
  }

}

export default Atable
