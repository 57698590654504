import React from 'react';
import {Link} from "react-router-dom";
import axios, {put} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DztImageGalleryComponent from 'reactjs-image-gallery';
import { Map, GeoJSON, TileLayer, Marker, Popup } from 'react-leaflet';
import Auth from "../../helper/Authentication";
const confirm = Modal.confirm;

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = {
          id: this.props.match.params.id,
          kode: null,
          kode_pertanahan: null,
          provinsi_id: 11,
          kabupaten_id:0,
          kecamatan_id:0,
          nomor_urut: null,
          desa_id:'',
          nomor_sk:'',
          tanggal_sk: new Date(),
          luas:'',
          luas_meter: '',
          luas_hektare: '',
          nama: '',
          koordinat_opt: "2",
          entri_koordinat_error: '',
          list_koordinat: [],
          file_geojson: null,
          file_geojson_new: null,
          lat: 1,
          long: 1,
          zoom: 19

         };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Kawasan Hutan";
        this.get_data();
        this.get_kabupaten_id_list();

    }

    get_data(){
        document.getElementById("loader").style.display = "inline";
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'kawasan_hutan/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                kabupaten_id: data.data.kabupaten[0].kabupaten_id,
       					kecamatan_id: data.data.kecamatan[0].kecamatan_id,
                kode: data.data.kode,
       					nomor_sk: data.data.nomor_sk,
       					tanggal_sk: new Date( data.data.tanggal_sk ),
                file_sk: data.data.file_sk,
       					luas_meter: data.data.luas,
                luas_hektare: data.data.luas/10000,
       					nama: data.data.nama,
                file_geojson: data.data.file_geojson,
                geojson: data.data.geojson
            }, () =>{
              this.get_kecamatan_id_list();
              this.get_desa_id_list();
              // hide loader
              document.getElementById("loader").style.display = "none";

              if( this.state.file_geojson ){
                this.load_json_from_url(api_url+this.state.file_geojson)
              }

            });
            }else{
              message.error( data.data );
            }

          }
        })
      }

      load_json_from_url( url ){
        document.getElementById("loader").style.display = "inline";
        axios.get( url )
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {
          this.setState({
            geojson_data: data,
            lat   : data.features[0].geometry.coordinates[0][0][1],
            long  : data.features[0].geometry.coordinates[0][0][0]
          }, () =>{
            // hide loader

          });
        })
        document.getElementById("loader").style.display = "none";
      }


    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        if( name == "luas_hektare" ){
          this.setState(
            {
              "luas_hektare": value,
              "luas_meter": value*10000
            }, () =>{
            this.get_kecamatan_id_list();
            this.get_desa_id_list();
          });
        }else if( name == "luas_meter" ){
          this.setState(
            {
              "luas_hektare": value/10000,
              "luas_meter": value
            }, () =>{
            this.get_kecamatan_id_list();
            this.get_desa_id_list();
          });
        }else{
          this.setState({[name]: value}, () =>{
            this.get_kecamatan_id_list();
            this.get_desa_id_list();
          });
        }


    }

    onChangeFile(e){
      const target = e.target;
      const name = target.name;
      if( name == "file_sk" ){
        this.setState({ file_sk:e.target.files[0] })
      }else if( name == "file_geojson_new"){
        this.setState({ file_geojson_new:e.target.files[0] })
      }else{

      }

    }


    tanggal = date => {
      this.setState({
        tanggal_sertifikat: date
      });
    };

    tanggal_izin = date => {
      this.setState({
        tanggal_izin: date
      });
    };


    handleSubmit(event) {
        event.preventDefault();
        document.getElementById("loader").style.display = "inline";
        let formData = new FormData();
        formData.append('kode',this.state.kode);
        formData.append('kabupaten_id',this.state.kabupaten_id);
        formData.append('kecamatan_id',this.state.kecamatan_id);
        formData.append('nomor_sk',this.state.nomor_sk);
        formData.append('tanggal_sk', this.state.tanggal_sk);
        formData.append('luas', this.state.luas_meter);
        formData.append('nama', this.state.nama);
        formData.append('file_geojson', this.state.file_geojson_new);

        if( this.state.list_koordinat.length > 0 ){
          this.state.list_koordinat.forEach((item) => {
              formData.append('koordinat[]', JSON.stringify(item) );
          });
        }

        if( this.state.file_sk ){
          formData.append('file_sk', this.state.file_sk);
        }

        if( this.state.file_geojson ){
          formData.append('file_geojson', this.state.file_geojson);
        }


        if( this.state.list_koordinat.length > 0 ){
          this.state.list_koordinat.forEach((item) => {
              formData.append('koordinat[]', JSON.stringify(item) );
          });
        }


        let header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) },
          'content-type': 'multipart/form-data'
        };

        put( api_url+'kawasan_hutan/edit/'+this.state.id, formData, header).then(
          response => response.data,
          error => {
            // alert(error)
          }
        ).then((data) => {
          document.getElementById("loader").style.display = "none";
          if( data !== undefined ){
            if( data.status === 'success' ){

              notification.open({
                message: 'Berhasil',
                description:'Data telah disimpan ke database!',
              });

              // reload
              this.componentDidMount()
              // this.props.history.push(`/admin/pertanahan/bangunan/edit/${this.state.id}`);

            }else{
              notification.open({
                  message: 'Error',
                  description: "Error",
                  icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                });
            }
          }

        })
  }



  get_kabupaten_id_list(){

    axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
        response => {
          this.setState({ kab_kota_list: response.data.data});
      },
      error => {
        alert(error)
      }

    );
  }

  select_option_for_kab_kota(){
    if( this.state.kab_kota_list !== undefined && typeof this.state.kab_kota_list == "object"){
      if( this.state.kab_kota_list.length > 0 ){
        const res = (
          <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
            <option value="" key={ Math.random() }>Pilih</option>
            {this.state.kab_kota_list.map(( value ) =>
              <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
            )}
          </select>
        );

        return res;

      }
    }
  }


  get_kecamatan_id_list(){
      axios.get(api_url+"wilayah/kecamatan/get_by_kabupaten/"+this.state.kabupaten_id).then(
          response => {
            this.setState({ kecamatan_id_list: response.data.data});
        },
        error => {
          alert(error)
        }

      );
    }

		select_option_for_kecamatan_id(){
      if( this.state.kecamatan_id_list !== undefined && typeof this.state.kecamatan_id_list == "object"){
        if( this.state.kecamatan_id_list.length > 0 ){
          const res = (
            <select name="kecamatan_id" className="form-control" onChange={this.handleChange} required>
              <option value="">Pilih</option>
              {this.state.kecamatan_id_list.map(( value ) =>
                <option value={value.kecamatan_id} key={ Math.random() } selected= {(value.kecamatan_id == this.state.kecamatan_id)?true:false}>{value.kecamatan}</option>
              )}
            </select>
          );

          return res;

        }
      }else{
        return(
          <select name="kecamatan_id" className="form-control" onChange={this.handleChange} required>
            <option value="">Pilih</option>
          </select>
        )
      }
    }

    get_desa_id_list(){
      axios.get(api_url+"wilayah/desa/get_by_kecamatan/"+this.state.kecamatan_id).then(
          response => {
            this.setState({ desa_id_list: response.data.data});
        },
        error => {
          alert(error)
        }

      );
    }

		select_option_for_desa_id(){
      if( this.state.desa_id_list !== undefined && typeof this.state.desa_id_list == "object"){
        if( this.state.desa_id_list.length > 0 ){
          const res = (
            <select name="desa_id" className="form-control" onChange={this.handleChange} required>
              <option value="">Pilih</option>
              {this.state.desa_id_list.map(( value ) =>
                <option value={value.desa_id} key={ Math.random() } selected= {(value.desa_id == this.state.desa_id)?true:false}>{value.desa}</option>
              )}
            </select>
          );

          return res;

        }
      }else{
        return(
          <select name="desa_id" className="form-control" onChange={this.handleChange} required>
            <option value="">Pilih</option>
          </select>
        )
      }
    }

    handleEntriKoordinat(event){
      let formElements = document.getElementById("formEntriKoordinat").elements;


        let input = {
          _id               : Math.random(),
          latitude  : this.state.latitude,
          longitude      : this.state.longitude
        }

        if( input.latitude !== "" && input.longitude !== "" ){

          let list = this.state.list_koordinat;
          list.push( input )

          this.setState({
            list_bahan_pokok: list,
            latitude: "",
            longitude: "",
            entri_koordinat_error: ""
          });
        }else{
          this.setState({
            entri_koordinat_error: "Latitude dan longitude tidak boleh kosong"
          });
        }
    }

    form_entri_koordinat(){
      let entri_koordinat_error = [];
        if( this.state.entri_koordinat_error ){
          entri_koordinat_error.push(
            <div className="alert alert-danger">
              {this.state.entri_koordinat_error}
            </div>
          )
      }

      return(
        <div className="row">
          <div className="col-md-6">
              <form onSubmit={this.handleEntriKoordinat} id="formEntriKoordinat">
                <div className="row">
                  <div className="col-md-12">
                      <div className="form-group">
                          <label>Latitude<span className="text-danger">*</span></label>
                          <input type="number" className="form-control" placeholder="Latitude" name="latitude" value={this.state.latitude} onChange={this.handleChange} required/>
                      </div>
                  </div>

                  <div className="col-md-12">
                      <div className="form-group">
                          <label>Longitude<span className="text-danger">*</span></label>
                          <input type="number" className="form-control" placeholder="Longitude" name="longitude" value={this.state.longitude} onChange={this.handleChange} required/>

                      </div>
                  </div>


                  <div className="col-md-12">
                    {entri_koordinat_error}
                  </div>

                  <div className="col-md-12">
                      <button type="button" className="btn btn-sm btn-danger btn-sm" onClick={() => this.handleEntriKoordinat()}><i className="fas fa-plus" /> Tambah</button>
                  </div>


                </div>
              </form>
          </div>

          <div className="col-md-6">
            {this.list_koordinat()}
          </div>
        </div>
      )
    }

  list_koordinat(){
      let list = this.state.list_koordinat;

      if( list.length > 0 ){
        let res = list.map(( value ) =>
        <tr key={ Math.random() }>
          <td>{value.latitude}</td>
          <td>{value.longitude}</td>
          <td>
            <button className="btn btn-sm btn-danger" onClick={() => this.hapusList(value._id)}><i className="fas fa-trash"></i></button> &nbsp;
          </td>
        </tr>
        );

        return (
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">Latitude</th>
                <th scope="col">Longitude</th>
                <th scope="col">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {res}
            </tbody>
          </table>
        );
      }
    }

    hapusList( id ){
      let list = this.state.list_koordinat;
      let t = this;

      for( let x in list ){
        let data = list[x];
        if( data._id == id ){
          list.splice(x, 1)
        }
      }

      t.setState({
        list_koordinat: list
      }, () => {
        notification.open({
          message: 'Koordinat Telah Dihapus',
          description:''
        });
      });

    }


    form_upload_geojson(){
      return(
        <div className="col-md-12 bg-secondary p-3">
            <h5 className="page-title">Ganti GeoJSON</h5>
            <div className="form-group">
              <label>Pilih File</label>
              <input type="file" name="file_geojson_new" className="form-control-file" onChange={this.onChangeFile} />

              <small class="form-text text-muted">Upload file dalam format json, maksimal 1 MB</small>
            </div>
        </div>
      )
    }




    render() {
      // file sertifikat
      let file_sk = [];
      if( this.state.file_sk != null ){
        file_sk.push(<a href={api_url+this.state.file_sk} target="_BLANK" className="btn primary-button">Lihat File</a>)
      }else{
        file_sk.push(<span className="text-danger">Tidak ada file</span>)
      }


      let file_geojson = null;
      if( this.state.file_geojson ){
        file_geojson = api_url+this.state.file_geojson;
      }



      return (
        <div>
            <div className="col-12 grid-margin">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">Kawasan Hutan</li>
                        <Link className="breadcrumb-item" to="/admin/kawasan_hutan/list">Kawasan Hutan</Link>
                        <li className="breadcrumb-item active">Edit</li>
                    </ol>
                </nav>

            </div>

            <div className="col-12 grid-margin">
                <div className="card">
                    <div className="card-body">
                        <h4 className="display-4 primary-color text-center">Edit</h4>
                        <br />

                        {/* Form Input */}
                        <form onSubmit={this.handleSubmit}>

              <fieldset>
                <legend>Wilayah</legend>
                <div className="row">
                  {/** Kabupaten **/}
                   <div className="col-md-6">
                     <div className="form-group">
                       <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                         { this.select_option_for_kab_kota() }
                     </div>
                   </div>


                   {/** Kecamatan **/}
                   <div className="col-md-6">
                     <div className="form-group">
                       <label>Kecamatan<span className="text-danger">*</span></label>
                        { this.select_option_for_kecamatan_id() }
                    </div>
                   </div>

              </div>
            </fieldset>

            <fieldset>
              <legend>Kode</legend>
              <div className="row">

                <div className="col-12">
                  <div className="form-group">
                    <input type="text" className="form-control" value={this.state.kode} disabled/>
                  </div>
                </div>


              </div>

            </fieldset>

            <fieldset>
              <legend>Data Tanah</legend>
              <div className="row">

              {/** Nama Bangunan **/}
              <div className="col-md-12">
                <div className="form-group">
                  <label>Nama<span className="text-danger">*</span></label>
                  <input type="text" className="form-control" placeholder="Nama" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                </div>
              </div>


                {/** Nomor SK **/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nomor SK</label>
                    <input type="text" className="form-control" placeholder="Nomor SK" name="nomor_sk" value={this.state.nomor_sk} onChange={this.handleChange} />
                  </div>
                </div>


              {/** Tanggal SK **/}
              <div className="col-md-6">
                <div className="form-group">
                  <label>Tanggal SK</label>
                  <div className="datepicker-container">
                    <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal_sk} onChange={date => this.tanggal(date)} />
                  </div>
                </div>
              </div>

              {/** Luas Tanah (Meter) **/}
              <div className="col-md-6">
                <div className="form-group">
                  <label>Luas Tanah (Dalam Meter)<span className="text-danger">*</span></label>
                  <input type="number" className="form-control" placeholder="Luas Tanah (Dalam Meter)" name="luas_meter" value={this.state.luas_meter} onChange={this.handleChange}/>
                </div>
              </div>

            {/** Luas Tanah (Hektare) **/}
            <div className="col-md-6">
              <div className="form-group">
                <label>Luas Tanah (Dalam Hektare)</label>
                <input type="number" className="form-control" placeholder="Luas Tanah" name="luas_hektare" value={this.state.luas_hektare} onChange={this.handleChange}/>
              </div>
            </div>

            {/** SK **/}
            <div className="col-md-6">
              <div className="form-group">
              <label>SK</label>
              <br/>
                {file_sk}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>File SK</label>
                <input type="file" name="file_sk" className="form-control-file" onChange={this.onChangeFile} />

                <small class="form-text text-muted">Upload file dalam format PDF, maksimal 2 MB</small>
              </div>
            </div>

            </div>
          </fieldset>

          <fieldset>
            <legend>Koordinat</legend>
                <div className="form-group row">
                <div className="col-md-12">

                  <Map
                    center={[this.state.lat, this.state.long]} zoom={this.state.zoom} id="leaflet-map-detail"
                  >

                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  {this.state.geojson_data ? (
                    <GeoJSON
                      key={`marker-01`}
                      data={this.state.geojson_data}
                      style={this.geoJSONStyle}
                      onEachFeature={this.onEachFeature}
                    />
                  ) :  ""}

                  </Map>
                </div>



                </div>

                <div className="col-md-12">
                  {(this.state.koordinat_opt == "1")?this.form_entri_koordinat():this.form_upload_geojson()}
                </div>
          </fieldset>

          <button stype="submit" className="btn primary-button">Simpan</button>
          <Link className="btn secondary-button" to="/admin/kawasan_hutan/list">Batal</Link>

          </form>


                    </div>
                </div>



            </div>

        </div>
        );
    }

}
