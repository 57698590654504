import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";

import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

import "react-datepicker/dist/react-datepicker.css";

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            provinsi_id:0,
            nama: '',
            kabupaten_id:0,
            nomor_izin: '',
            tanggal_berita_acara: new Date(),
            file_sertifikat: null,
            nomor_pilar: '',
            list_koordinat: [],
            file_geojson: null,
            status: '',
            luas:'',
            luas_meter: '',
            luas_hektare: '',
            koordinat_opt: "2",
            entri_koordinat_error: '',
            displayColorPicker: false,
            warna_palette:null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Batas Kabupaten";
        this.get_provinsi_id_list();

        //##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        if( name == "luas_hektare" ){
          this.setState(
            {
              "luas_hektare": value,
              "luas_meter": value*10000
            }, () =>{
              this.get_kabupaten_id_list();
            });
        }else if( name == "luas_meter" ){
          this.setState(
            {
              "luas_hektare": value/10000,
              "luas_meter": value
            }, () =>{
              this.get_kabupaten_id_list();
            });
        }else{
          this.setState({[name]: value}, () => {
              this.get_kabupaten_id_list();
          });
        }


    }

    onChangeFile(e){
        const target = e.target,
            name = target.name;

        if( name == "file_sertifikat" ){
            this.setState({ file_sertifikat:e.target.files[0] })
        }else if( name == "file_geojson"){
            this.setState({ file_geojson:e.target.files[0] })
        }else{

        }
    }

    tanggal_berita_acara = date => {
        this.setState({
            tanggal_berita_acara: date
        });
    };

    handleSubmit(event) {
        event.preventDefault();

        document.getElementById("loader").style.display = "inline";
        let formData = new FormData();

        formData.append('provinsi_id',this.state.provinsi_id);
        formData.append('nama',this.state.nama);
        formData.append('kabupaten_id',this.state.kabupaten_id);
        formData.append('nomor_izin', this.state.nomor_izin);
        formData.append('luas', this.state.luas_meter);
        formData.append('tanggal_berita_acara', this.state.tanggal_berita_acara);
        formData.append('file_sertifikat', this.state.file_sertifikat);
        formData.append('nomor_pilar', this.state.nomor_pilar);
        formData.append('warna_palette', this.state.warna_palette);
        formData.append('file_geojson', this.state.file_geojson);

        if( this.state.list_koordinat.length > 0 ){
            this.state.list_koordinat.forEach((item) => {
                formData.append('koordinat[]', JSON.stringify(item) );
            });
        }

        let header = {
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) },
            'content-type': 'multipart/form-data'
        };

        post( api_url+'batas_wilayah/batas_kabupaten/add', formData, header).then(
            response => response.data,
            error => {
                // alert(error)
            }
        ).then((data) => {
            document.getElementById("loader").style.display = "none";
            if( data !== undefined ){
                if( data.status === 'success' ){

                    notification.open({
                        message: 'Berhasil',
                        description:'Data telah disimpan ke database!',
                    });

                    // inserted ID : data.data
                    this.props.history.push(`/admin/batas_wilayah/batas_kabupaten/detail/${data.data}`);

                }else{
                    notification.open({
                        message: 'Error',
                        description: data.data,
                        icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                    });
                }
            }

        })
    }

    get_provinsi_id_list(){
        axios.get(api_url+"wilayah/provinsi/get").then(
            response => {
                this.setState({ provinsi_id_list: response.data.data});
            },
            error => {
                alert(error)
            }
        );
    }

    select_option_for_provinsi_id(){
        if( this.state.provinsi_id_list !== undefined && typeof this.state.provinsi_id_list == "object"){
            if( this.state.provinsi_id_list.length > 0 ){
                const res = (
                    <select name="provinsi_id" className="form-control" onChange={this.handleChange} required>
                        <option value="" key={ Math.random() }>Pilih</option>
                        {this.state.provinsi_id_list.map(( value ) =>
                            <option value={value.provinsi_id} key={ Math.random() } selected= {(value.provinsi_id == this.state.provinsi_id)?true:false}>{value.provinsi}</option>
                        )}
                    </select>
                );

                return res;

            }
        }
    }

    get_kabupaten_id_list(){

        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/"+this.state.provinsi_id).then(
            response => {
                this.setState({ kab_kota_list: response.data.data});
            },
            error => {
                alert(error)
            }

        );
    }

    select_option_for_kab_kota(){
        if( this.state.kab_kota_list !== undefined && typeof this.state.kab_kota_list == "object"){
            if( this.state.kab_kota_list.length > 0 ){
                const res = (
                    <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
                        <option value="" key={ Math.random() }>Pilih</option>
                        {this.state.kab_kota_list.map(( value ) => {
                          if(value.kabupaten_id == this.state.kabupaten_id){
                            if( this.state.nama != value.kabupaten ){
                              this.setState({ nama: value.kabupaten })
                            }

                          }
                          return(
                            <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
                          )
                        }

                        )}
                    </select>
                );

                return res;

            }
        }
    }



    handleEntriKoordinat(event){
        let formElements = document.getElementById("formEntriKoordinat").elements;

        let input = {
            _id         : Math.random(),
            latitude    : this.state.latitude,
            longitude   : this.state.longitude
        };

        if( input.latitude !== "" && input.longitude !== "" ){

            let list = this.state.list_koordinat;
            list.push( input )

            this.setState({
                list_bahan_pokok: list,
                latitude: "",
                longitude: "",
                entri_koordinat_error: ""
            });
        }else{
            this.setState({
                entri_koordinat_error: "Latitude dan longitude tidak boleh kosong"
            });
        }
    }

    form_entri_koordinat() {
        let entri_koordinat_error = [];
        if (this.state.entri_koordinat_error) {
            entri_koordinat_error.push(
                <div className="alert alert-danger">
                    {this.state.entri_koordinat_error}
                </div>
            )
        }

        return(
            <div className="row">
                <div className="col-md-6">
                    {/*<form onSubmit={this.handleEntriKoordinat} id="formEntriKoordinat">*/}
                        <div className="row" id="formEntriKoordinat">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Latitude<span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" placeholder="Latitude" name="latitude" value={this.state.latitude} onChange={this.handleChange} required/>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Longitude<span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" placeholder="Longitude" name="longitude" value={this.state.longitude} onChange={this.handleChange} required/>

                                </div>
                            </div>


                            <div className="col-md-12">
                                {entri_koordinat_error}
                            </div>

                            <div className="col-md-12">
                                <button type="button" className="btn btn-sm btn-danger btn-sm" onClick={() => this.handleEntriKoordinat()}><i className="fas fa-plus" /> Tambah</button>
                            </div>


                        </div>
                    {/*</form>*/}
                </div>

                <div className="col-md-6">
                    {this.list_koordinat()}
                </div>
            </div>
        )
    }

    list_koordinat(){
        let list = this.state.list_koordinat;

        if( list.length > 0 ){
            let res = list.map(( value ) =>
                <tr key={ Math.random() }>
                    <td>{value.latitude}</td>
                    <td>{value.longitude}</td>
                    <td>
                        <button className="btn btn-sm btn-danger" onClick={() => this.hapusList(value._id)}><i className="fas fa-trash"></i></button> &nbsp;
                    </td>
                </tr>
            );

            return (
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th scope="col">Latitude</th>
                        <th scope="col">Longitude</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {res}
                    </tbody>
                </table>
            );
        }
    }

    hapusList( id ){
        let list = this.state.list_koordinat;
        let t = this;

        for( let x in list ){
            let data = list[x];
            if( data._id == id ){
                list.splice(x, 1)
            }
        }

        t.setState({
            list_koordinat: list
        }, () => {
            notification.open({
                message: 'Koordinat Telah Dihapus',
                description:''
            });
        });

    }

    form_upload_geojson(){
        return(
            <div className="col-md-12 bg-secondary p-3">
                <div className="form-group">
                    <label>Pilih File</label>
                    <input type="file" name="file_geojson" className="form-control-file" onChange={this.onChangeFile} />

                    <small className="form-text text-muted">Upload file dalam format json, maksimal 1 MB</small>
                </div>
            </div>
        )
    }

    // handle color picker
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChangeColor = (color) => {
        this.setState({ warna_palette: color.hex})
    };

    colorPicker() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '50px',
                    height: '100%',
                    borderRadius: '2px',
                    backgroundColor: `${ this.state.warna_palette}`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                    top: '40px',
                    right: '0'
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div className="input-group">
                <input type="hidden" name="warna_palette" value={this.state.warna_palette} onChange={ this.handleChangeColor }/>
                <input type="text" className="form-control" name="" placeholder="#FFFFFF" value={this.state.warna_palette} onChange={ this.handleChangeColor } disabled/>
                    <div className="input-group-append" style={ styles.swatch } onClick={ this.handleClick }>
                        <div style={ styles.color } />
                    </div>
                    { this.state.displayColorPicker ? <div style={ styles.popover }>
                        <div style={ styles.cover } onClick={ this.handleClose }/>
                        <SketchPicker color={ this.state.warna_palette } onChange={ this.handleChangeColor } />
                    </div> : null }
            </div>
        )
    };

    //##cst_function##/

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Batas Wilayah</li>
                            <Link className="breadcrumb-item" to="/admin/batas_wilayah/batas_kabupaten/list">Batas Kabupaten</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Batas Kabupaten</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <fieldset>
                                    <legend>Wilayah</legend>
                                    <div className="row">

                                        {/** Kode Provinsi **/}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Provinsi<span className="text-danger">*</span></label>
                                                { this.select_option_for_provinsi_id() }
                                            </div>
                                        </div>

                                        {/** Kabupaten **/}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                                                { this.select_option_for_kab_kota() }
                                            </div>
                                        </div>

                                        {/*##form_input##*/}

                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Batasan</legend>
                                    <div className="row">

                                        {/** Nomor Kesepakatan **/}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Nomor Berita Acara<span className="text-danger">*</span></label>
                                                <input type="number" className="form-control" placeholder="Nomor Berita Acara" name="nomor_izin" value={this.state.nomor_izin} onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        {/** Tanggal Kesepakatan **/}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Tanggal Berita Acara Kesepakatan</label>
                                                <div className="datepicker-container">
                                                    <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal_berita_acara} onChange={date => this.tanggal_berita_acara(date)} />
                                                </div>
                                            </div>
                                        </div>

                                        {/** Nomor Pilar **/}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Nomor Pilar<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Nomor Pilar Batasan Utama" name="nomor_pilar" value={this.state.nomor_pilar} onChange={this.handleChange} required/>
                                            </div>
                                        </div>

                                        {/** Luas Tanah (Meter) **/}
                                				<div className="col-md-6">
                                          <div className="form-group">
                                            <label>Luas Tanah (Dalam Meter)<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" placeholder="Luas Tanah (Dalam Meter)" name="luas_meter" value={this.state.luas_meter} onChange={this.handleChange}/>
                                          </div>
                                        </div>

                              				{/** Luas Tanah (Hektare) **/}
                              				<div className="col-md-6">
                                        <div className="form-group">
                                          <label>Luas Tanah (Dalam Hektare)</label>
                                          <input type="number" className="form-control" placeholder="Luas Tanah" name="luas_hektare" value={this.state.luas_hektare} onChange={this.handleChange}/>
                                        </div>
                                      </div>

                                        {/** Status Kesepakatan **/}
                                        {/*<div className="col-md-6">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <label>Status</label>*/}
                                        {/*        <select onChange={this.handleChange} className="form-control">*/}
                                        {/*            <option value="">-- Pilih --</option>*/}
                                        {/*            <option value="draft">Draft</option>*/}
                                        {/*            <option value="publish">Publish</option>*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/** File PDF **/}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>File PDF</label>
                                                <input type="file" name="file_sertifikat" className="form-control-file" onChange={this.onChangeFile} />

                                                <small className="form-text text-muted">Upload file dalam format PDF, maksimal 2 MB</small>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>

                                {/*Koordinat*/}
                                <fieldset>
                                    <legend>Koordinat</legend>

                                    <div className="row form-group">

                                        <div className="col-sm-3">
                                            <div className="form-radio mt-2">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input"
                                                           name="koordinat_opt" value="1"
                                                           checked={"1" === this.state.koordinat_opt}
                                                           onChange={this.handleChange}/>
                                                    Input Form
                                                    <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className="form-radio mt-2">
                                                <label className="form-check-label">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="koordinat_opt"
                                                        value="2"
                                                        checked={"2" === this.state.koordinat_opt}
                                                        onChange={this.handleChange}/>
                                                    Upload Geojson
                                                    <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            {(this.state.koordinat_opt == "1") ? this.form_entri_koordinat() : this.form_upload_geojson()}
                                        </div>
                                    </div>
                                </fieldset>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>

                        </div>
                    </div>

                </div>

            </div>
        );
    }

}
