import React from 'react';
import {Link} from "react-router-dom";
import axios, {put} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import moment from 'moment';
import 'moment/locale/id';
import Auth from "../../../helper/Authentication";
import { Map, GeoJSON, TileLayer, Marker, Popup } from 'react-leaflet';
import DztImageGalleryComponent from 'reactjs-image-gallery';

const confirm = Modal.confirm;
const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = {
          id: this.props.match.params.id,
          kode: null,
          kode_pertanahan: null,
          provinsi_id: 11,
          kabupaten:null,
          kecamatan:null,
          desa:null,
          nomor_urut: null,
          nomor_sertifikat:'',
          tanggal_sertifikat: new Date(),
          file_sertifikat: null,
          luas_meter:'',
          luas_hektare: '',
          nama:'',
          nama_tertera: '',
          nomor_izin:'',
          tanggal_izin: new Date(),
          peruntukan:'',
          sertifikat_hak_pakai:'',
          foto_depan: '',
          foto_belakang: '',
          foto_kanan: '',
          foto_kiri: '',
          foto_atas: '',
          status: '',
          koordinat_opt: "1",
          entri_koordinat_error: '',
          list_koordinat: null,
          file_geojson: null,
          geojson_data: null,
          lat: 1,
          long: 1,
          zoom: 19

         };

    }

    componentDidMount() {
        document.title = process.env.REACT_APP_APP_NAME + "::Bangunan Dinas/Kantor";
        this.get_data();
        // loader
        document.getElementById("loader").style.display = "inline";
    }

    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'pertanahan/bangunan/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                kabupaten: data.data.kabupaten[0].kabupaten,
       					kecamatan: data.data.kecamatan[0].kecamatan,
       					desa: data.data.desa[0].desa,
                kode: data.data.kode,
       					nomor_sertifikat: data.data.nomor_sertifikat,
       					tanggal_sertifikat: data.data.tanggal_sertifikat,
                file_sertifikat: data.data.file_sertifikat,
       					luas_meter: data.data.luas,
                luas_hektare: data.data.luas/10000,
       					nama: data.data.nama,
       					nomor_izin: data.data.nomor_izin,
       					tanggal_izin: data.data.tanggal_izin,
       					peruntukan: data.data.peruntukan,
                status: data.data.status,
       					sertifikat_hak_pakai: data.data.sertifikat_hak_pakai,
                foto_depan: data.data.foto_depan,
                foto_belakang: data.data.foto_belakang,
                foto_kiri: data.data.foto_kiri,
                foto_kanan: data.data.foto_kanan,
                foto_atas: data.data.foto_atas,
                file_geojson: data.data.file_geojson,
                geojson: data.data.geojson
            }, () =>{
              // hide loader
              document.getElementById("loader").style.display = "none";

              if( this.state.file_geojson ){
                this.load_json_from_url(api_url+this.state.file_geojson)
              }

            });
            }else{
              message.error( data.data );
            }

          }
        })
      }

      load_json_from_url( url ){
        document.getElementById("loader").style.display = "inline";
        axios.get( url )
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {
          this.setState({
            geojson_data: data,
            lat   : data.features[0].geometry.coordinates[0][0][1],
            long  : data.features[0].geometry.coordinates[0][0][0]
          }, () =>{
            // hide loader
            document.getElementById("loader").style.display = "none";
          });
        })
      }

      geoJSONStyle() {
       return {
         color: '#e6b901',
         weight: 1,
         fillOpacity: 0.5,
         fillColor: '#00c823',
       }
     }

     onEachFeature(feature: Object, layer: Object) {
       const popupContent = ` <Popup><br />${feature.properties.Name}</pre></Popup>`
       layer.bindPopup(popupContent)
     }

     publish(status){
       const e     = this;

       const header = {
         headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
       };
       confirm({
         title: "Apakah anda ingin mempublish data?",
         content: "",
         onOk() {
           document.getElementById("loader").style.display = "inline";

           let form_data =
           {
             status: status
           };

           var header = {
             headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
           };

           axios.post( api_url+'pertanahan//bangunan/publish/'+e.state.id, form_data, header
           ).then(
             response => response.data,
             error => {
               alert(error)
             }
           )
           .then((data) => {
             if( data.status === 'success' ){

               notification.open({
                 message: 'Berhasil',
                 description:'Data telah disimpan ke database!',
               });

               e.componentDidMount()

             }else{
               notification.open({
                   message: 'Error',
                   description: data.data,
                   icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                 });
             }

           })

         }
       });
     }


    render() {
        // file sertifikat
        let file_sertifikat = [];
        if( this.state.file_sertifikat != null ){
          file_sertifikat.push(<a href={api_url+this.state.file_sertifikat} target="_BLANK" className="btn primary-button">Lihat File</a>)
        }else{
          file_sertifikat.push(<span className="text-danger">Tidak ada file</span>)
        }
        // foto
        let photos = [];

        if( this.state.foto_depan ){
          photos.push( {
            url: api_url+this.state.foto_depan,
            title: 'Foto Depan',
            thumbUrl: api_url+this.state.foto_depan,
          }) ;
        }

        if( this.state.foto_belakang ){
          photos.push( {
            url: api_url+this.state.foto_belakang,
            title: 'Foto Belakang',
            thumbUrl: api_url+this.state.foto_belakang,
          }) ;
        }

        if( this.state.foto_kanan ){
          photos.push( {
            url: api_url+this.state.foto_kanan,
            title: 'Foto Kanan',
            thumbUrl: api_url+this.state.foto_kanan,
          }) ;
        }

        if( this.state.foto_kiri ){
          photos.push( {
            url: api_url+this.state.foto_kiri,
            title: 'Foto Kiri',
            thumbUrl: api_url+this.state.foto_kiri,
          }) ;
        }

        if( this.state.foto_atas ){
          photos.push( {
            url: api_url+this.state.foto_atas,
            title: 'Foto Atas',
            thumbUrl: api_url+this.state.foto_atas,
          }) ;
        }

        let file_geojson = null;
        if( this.state.file_geojson ){
          file_geojson = api_url+this.state.file_geojson;
        }

        let status = [];
        let publish_btn = [];
        if(this.state.status == "draft"){
          status.push(<div className="badge badge-warning">DRAFT</div>)

          if( localStorage.getItem('is_verifikator') == "true" ){
            publish_btn.push(
              <button type="button" onClick={() => this.publish("publish")} className="btn primary-button btn-sm" title="Publish">
                <i className="fas fa-check"></i> Publish
              </button>
            )
          }

        }else if ( this.state.status == "publish" ) {
          status.push(<div className="badge badge-primary">PUBLISH</div>)

          if( localStorage.getItem('is_verifikator') == "true" ){
            publish_btn.push(
              <button type="button" onClick={() => this.publish("draft")} className="btn primary-button btn-sm" title="Draft">
                <i className="fas fa-check"></i> Draft
              </button>
            )
          }

        }

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Data Pertanahan</li>
                            <Link className="breadcrumb-item" to="/admin/pertanahan/bangunan/list">Bangunan Dinas/Kantor</Link>
                            <li className="breadcrumb-item active">Detail</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Detail Bangunan Dinas/Kantor</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>


                  <fieldset>
                    <legend>Wilayah</legend>
                    <div className="row">
                      {/** Kabupaten **/}
                       <div className="col-md-12">
                         <div className="form-group">
                           <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                           <input type="text" className="form-control" value={this.state.kabupaten} disabled/>
                         </div>
                       </div>

                       {/** Kecamatan **/}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Kecamatan<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={this.state.kecamatan} disabled/>
                          </div>
                        </div>

                        {/** Desa **/}
                         <div className="col-md-6">
                           <div className="form-group">
                             <label>Desa<span className="text-danger">*</span></label>
                             <input type="text" className="form-control" value={this.state.desa} disabled/>
                           </div>
                         </div>




                  </div>
                </fieldset>

                <fieldset>
                  <legend>Kode</legend>
                  <div className="row">

                    <div className="col-12">
                      <div className="form-group">
                        <input type="text" className="form-control" value={this.state.kode} disabled/>
                      </div>
                    </div>

                  </div>

                </fieldset>

                <fieldset>
                  <legend>Bangunan</legend>
                  <div className="row">
                  {/** Nama Bangunan **/}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nama Bangunan<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.nama} disabled/>
                    </div>
                  </div>

            				{/** Nomor Sertifikat **/}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Nomor Sertifikat<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" value={this.state.nomor_sertifikat} disabled/>
                      </div>
                    </div>


          				{/** Tanggal Sertifikat **/}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tanggal Sertifikat<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={moment(this.state.tanggal_sertifikat).format("D MMMM YYYY")} disabled/>
                    </div>
                  </div>

                  {/** Luas Tanah (Meter) **/}
          				<div className="col-md-6">
                    <div className="form-group">
                      <label>Luas Tanah (Dalam Meter)<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.luas_meter} disabled/>
                    </div>
                  </div>

        				{/** Luas Tanah (Hektare) **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Luas Tanah (Dalam Hektare)<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" value={this.state.luas_hektare} disabled/>
                  </div>
                </div>


        				{/** Nama Pada Sertifikat **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Pada Sertifikat</label>
                    <input type="text" className="form-control" value={this.state.nama} disabled/>
                  </div>
                </div>

                </div>
              </fieldset>

              <fieldset>
                <legend>Izin</legend>
                  <div className="row">
            				{/** Nomor Izin **/}
            				<div className="col-md-12">
                      <div className="form-group">
                        <label>Nomor Izin</label>
                        <input type="text" className="form-control" value={this.state.nomor_izin} disabled/>
                      </div>
                    </div>


            				{/** Tanggal Izin **/}
            				<div className="col-md-6">
                      <div className="form-group">
                        <label>Tanggal Izin</label>
                        <input type="text" className="form-control" value={moment(this.state.tanggal_izin).format("D MMMM YYYY")} disabled/>
                      </div>
                    </div>


            				{/** Peruntukan **/}
            				<div className="col-md-6">
                      <div className="form-group">
                        <label>Peruntukan</label>
                        <input type="text" className="form-control" value={this.state.peruntukan} disabled/>
                      </div>
                    </div>


          				{/** Sertifikat Hak Pakai **/}
          				<div className="col-md-12">
                    <div className="form-group">
                      <label>Sertifikat Hak Pakai/Akta Jual Beli</label><br/>
                      {file_sertifikat}
                    </div>
                  </div>

                </div>

              </fieldset>

              <div className="col-md-12">
                <fieldset>
                  <legend>Koordinat</legend>
                  <div className="col-md-12">


                    <Map
                      center={[this.state.lat, this.state.long]} zoom={this.state.zoom} id="leaflet-map-detail"
                    >

                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {this.state.geojson_data ? (
                      <GeoJSON
                        key={`marker-01`}
                        data={this.state.geojson_data}
                        style={this.geoJSONStyle}
                        onEachFeature={this.onEachFeature}
                      />
                    ) :  ""}

                    </Map>
                  </div>
                </fieldset>

              </div>

              <div className="col-md-12">

                <fieldset>
                  <legend>Foto</legend>
                  <div className="row">
                    <DztImageGalleryComponent images={photos} />
                  </div>
                </fieldset>
              </div>

              <div className="col-md-12">

                <fieldset>
                  <legend>Status</legend>
                  {status}
                </fieldset>
                {publish_btn}
                <Link className="btn secondary-button" to="/admin/pertanahan/bangunan/list">Kembali</Link>

              </div>


              </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
