import React, { Component } from "react";
import { Link } from "react-router-dom";

import '../Public.css';

class Header extends Component {

  componentDidMount(){

  }

  render() {
    return (
      <div>
        <div className="header-shape"></div>
        <div className="container">

          <nav className="navbar navbar-expand-lg navbar-custom-public">

            <Link className="navbar-brand" to="/">
                <img src="/logo_white.png" width="150"/>
            </Link>

            <button className="navbar-toggler-public" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars" aria-hidden="true"></i>
            </button>

            <div className="collapse navbar-collapse w-100 order-3" id="navbarNav">
                <ul className="navbar-nav navbar-custom-public-nav ml-auto">
                    <li className="nav-item nav-public">
                      <Link className="nav-link" to="/">Beranda</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/tentang">Tentang</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/kontak">Kontak</Link>
                    </li>
                </ul>
            </div>
        </nav>
      </div>
    </div>

  );
  }
}

export default Header;
