import React from 'react';
import { Link } from "react-router-dom";
import Atable from "../../../lib/Atable";
import axios from "axios";
import { message, Modal, Button, Tooltip, Skeleton } from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Hak_Akses extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            total_record  : "",
            total_filter  : "",
            limit         : 10,
            active_page   : 1,
            search        : "",
            loading       : true,
            table_data    : ""
        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount(){
         document.title = process.env.REACT_APP_APP_NAME+"::Users";
         this.load_data();
     }

     head(){
         return ["Nama Depan", "Nama Belakang", "Username", "Aktif", "Aksi"];
     }

     handleChange(event) {
         const target = event.target;
         const value = target.type === 'checkbox' ? target.checked : target.value;
         const name = target.name;

            this.setState(
                { [name]: value }, () => {
                    this.load_data();
            })
     }

     load_data(){
         message.loading('Loading...', 3.5);
         var header = {
             headers: { 'api_token': localStorage.getItem('token') }
         };

         axios.get(api_url+"acl/users/get_users",header).then( response => {
                this.setState({
                    table_data: response.data.data,
                    total_record: response.data.recordsTotal,
                    total_filter: response.data.recordsFiltered,
                    loading:false
                });
                message.destroy();
        }, error => {
                alert(error)  
        });
     }

     generate_rows(){
         const data = this.state.table_data;
         if( typeof data == "object" && data !== null ){

                 const res = data.map((value) =>
                         <tr key={ Math.random() }>
                             <td>{value.first_name}</td>
                             <td>{value.last_name}</td>
                             <td>{value.username}</td>
                             <td>{value.is_active == true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-check text-danger"></i>}</td>
                             <td>
                                    <Tooltip title="Edit User">
                                         <Link className="btn secondary-button btn-sm mr-3" to={`users/edit_users/${value._id}`}>
                                             <i className="fas fa-edit"></i>
                                         </Link>
                                    </Tooltip>

                                    <Tooltip title="Ganti Password">
                                         <Link className="btn primary-button btn-sm" to={`users/password/${value._id}`}>
                                             <i className="fas fa-key"></i>
                                         </Link>
                                    </Tooltip>
                             </td>
                             
                         </tr>
                 );
                 return res;
         }
     }

    render() {
        return (
            <div>
                {/* Breadcrumb */}
                <div className="col-12 grid-margin">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <li className="breadcrumb-item active">Pengguna Aplikasi</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Hak Akses Pengguna</h4>
                            <br />
                            <Link className="btn primary-button" to="users/add_users">
                                Tambah Pengguna
                            </Link>
                            <hr className="divider soft" />

                        <Skeleton loading={this.state.loading} active>
                            <Atable
                                head={ this.head() }
                                data={ this.generate_rows() }
                                total_filter={ this.state.total_filter }
                                total_record={ this.state.total_record }
                                current_page={this.state.active_page}
                                search={this.state.search}
                                handleChange={this.handleChange}
                                limit={this.state.limit}
                            />
                        </Skeleton>

                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
