import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import {message, Skeleton} from 'antd';

import Header from "./include/Header";
import Footer from "./include/Footer";

import './Public.css';

const api_url = process.env.REACT_APP_API_URL;

export default class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      id: this.props.match.params.id,
      page_content: "", 
      error_content: null,
      loading: true 
    };

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME;
     this.get_page();
  }

  get_page(){
    axios.get( api_url+'page/get/'+this.state.id)
    .then(
      response => response.data,
      error => { this.setState({ error_content: "Halaman Tidak Ditemukan" , loading: false }) }
    )
    .then((data) => {

      if( typeof data == 'object' ){
        if( data.status === 'success' ){
          this.setState({ page_content: data.data, loading: false });
        }else{
          message.error( data.data );
        }
      }
    })
  }

  render() {
    return (
      <div>
        <Header />

        <div className="container">
        
            <div className="col-md-12 page-content">
              <Skeleton loading={this.state.loading} active>
                <h1 className="homepage-title text-center secondary-color">{this.state.page_content.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: this.state.page_content.content }} />
                <h1 className="homepage-title text-center secondary-color">{this.state.error_content}</h1>
              </Skeleton>
            </div>

            <div class='box'>
              <div class='wave -one'></div>
              <div class='wave -two'></div>
              <div class='wave -three'></div>
            </div>
        
        <Footer />
        </div>
    </div>
    );
  }

}

