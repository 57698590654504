import React from 'react';
import { Link } from "react-router-dom";
import './Public.css';

export default class Notfound extends React.Component {
  constructor(props) {
    super(props);

    this.state = { page_content: "", loading: true };

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Halaman Tidak Ditemukan";
  }


  render() {
    return (
      <div>
        <div className="container">
        
            <div className="col-md-12 page-content mt-4">
                <h1 className="homepage-title text-center primary-color">404</h1>
                <h1 className="homepage-title text-center secondary-color">Halaman Tidak Ditemukan</h1>
                <br/>
                <center>
                  <Link className="btn btn-menu bg-primary" to="/">
                      <i className="fas fa-home"></i> Beranda
                  </Link>
                </center>
                
        
            </div>

            <div class='box'>
              <div class='wave -one'></div>
              <div class='wave -two'></div>
              <div class='wave -three'></div>
            </div>
        
        </div>
    </div>
    );
  }

}

