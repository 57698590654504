import React from 'react';
import {Link} from "react-router-dom";
import axios, {put} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import moment from 'moment';
import 'moment/locale/id';
import Auth from "../../../helper/Authentication";
import { Map, GeoJSON, TileLayer, Marker, Popup } from 'react-leaflet';
import DztImageGalleryComponent from 'reactjs-image-gallery';

const confirm = Modal.confirm;
const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = {
          id: this.props.match.params.id,
          kode: null,
          kode_pertanahan: null,
          provinsi_id: 11,
          kabupaten_id:0,
          kecamatan_id:0,
          nomor_urut: null,
          desa_id:0,
          nomor_sk:'',
          tanggal_sk: new Date(),
          tanggal_berakhir: new Date(),
          luas:'',
          luas_meter: '',
          luas_hektare: '',
          nama: '',
          status: '',
          koordinat_opt: "2",
          entri_koordinat_error: '',
          list_koordinat: [],
          file_geojson: null,
          file_geojson_new: null,
          lat: 1,
          long: 1,
          zoom: 19

         };

    }

    componentDidMount() {
        document.title = process.env.REACT_APP_APP_NAME + "::Lokasi Transmigrasi";
        this.get_data();
        // loader
        document.getElementById("loader").style.display = "inline";
    }

    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'lokasi_transmigrasi/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                kabupaten: data.data.kabupaten[0].kabupaten,
       					kecamatan: data.data.kecamatan[0].kecamatan,
                desa: data.data.desa[0].desa,
                kode: data.data.kode,
       					nomor_sk: data.data.nomor_sk,
       					tanggal_sk: new Date( data.data.tanggal_sk ),
                file_sk: data.data.file_sk,
                file_peserta: data.data.file_peserta,
       					luas_meter: data.data.luas,
                luas_hektare: data.data.luas/10000,
       					nama: data.data.nama,
                status: data.data.status,
                file_geojson: data.data.file_geojson,
                geojson: data.data.geojson
            }, () =>{
              // hide loader
              document.getElementById("loader").style.display = "none";

              if( this.state.file_geojson ){
                this.load_json_from_url(api_url+this.state.file_geojson)
              }

            });
            }else{
              message.error( data.data );
            }

          }
        })
      }

      load_json_from_url( url ){
        document.getElementById("loader").style.display = "inline";
        axios.get( url )
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {
          this.setState({
            geojson_data: data,
            lat   : data.features[0].geometry.coordinates[0][0][1],
            long  : data.features[0].geometry.coordinates[0][0][0]
          }, () =>{
            // hide loader
            document.getElementById("loader").style.display = "none";
          });
        })
      }

      geoJSONStyle() {
       return {
         color: '#e6b901',
         weight: 1,
         fillOpacity: 0.5,
         fillColor: '#00c823',
       }
     }

     onEachFeature(feature: Object, layer: Object) {
       const popupContent = ` <Popup><br />${feature.properties.Name}</pre></Popup>`
       layer.bindPopup(popupContent)
     }

     publish(status){
       const e     = this;

       const header = {
         headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
       };
       confirm({
         title: "Apakah anda ingin mempublish data?",
         content: "",
         onOk() {
           document.getElementById("loader").style.display = "inline";

           let form_data =
           {
             status: status
           };

           var header = {
             headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
           };

           axios.post( api_url+'lokasi_transmigrasi/publish/'+e.state.id, form_data, header
           ).then(
             response => response.data,
             error => {
               alert(error)
             }
           )
           .then((data) => {
             if( data.status === 'success' ){

               notification.open({
                 message: 'Berhasil',
                 description:'Data telah disimpan ke database!',
               });

               e.componentDidMount()

             }else{
               notification.open({
                   message: 'Error',
                   description: data.data,
                   icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                 });
             }

           })

         }
       });
     }


    render() {
        // file sertifikat
        let file_sk = [];
        if( this.state.file_sk != null ){
          file_sk.push(<a href={api_url+this.state.file_sk} target="_BLANK" className="btn primary-button">Lihat File</a>)
        }else{
          file_sk.push(<span className="text-danger">Tidak ada file</span>)
        }

        // file peserta
        let file_peserta = [];
        if( this.state.file_peserta != null ){
          file_peserta.push(<a href={api_url+this.state.file_peserta} target="_BLANK" className="btn primary-button">Lihat File</a>)
        }else{
          file_peserta.push(<span className="text-danger">Tidak ada file</span>)
        }

        let file_geojson = null;
        if( this.state.file_geojson ){
          file_geojson = api_url+this.state.file_geojson;
        }

        let status = [];
        let publish_btn = [];
        if(this.state.status == "draft"){
          status.push(<div className="badge badge-warning">DRAFT</div>)

          if( localStorage.getItem('is_verifikator') == "true" ){
            publish_btn.push(
              <button type="button" onClick={() => this.publish("publish")} className="btn primary-button btn-sm" title="Publish">
                <i className="fas fa-check"></i> Publish
              </button>
            )
          }

        }else if ( this.state.status == "publish" ) {
          status.push(<div className="badge badge-primary">PUBLISH</div>)

          if( localStorage.getItem('is_verifikator') == "true" ){
            publish_btn.push(
              <button type="button" onClick={() => this.publish("draft")} className="btn primary-button btn-sm" title="Draft">
                <i className="fas fa-check"></i> Draft
              </button>
            )
          }

        }

        return (
          <div>
              <div className="col-12 grid-margin">

                  <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item">Kawasan Khusus</li>
                          <Link className="breadcrumb-item" to="/admin/kawasan_khusus/lokasi_transmigrasi/list">Lokasi Transmigrasi</Link>
                          <li className="breadcrumb-item active">Detail</li>
                      </ol>
                  </nav>

              </div>

              <div className="col-12 grid-margin">
                  <div className="card">
                      <div className="card-body">
                          <h4 className="display-4 primary-color text-center">Detail</h4>
                          <br />

                          {/* Form Input */}
                          <form onSubmit={this.handleSubmit}>

                          <fieldset>
                            <legend>Wilayah</legend>
                            <div className="row">
                              {/** Kabupaten **/}
                               <div className="col-md-6">
                                 <div className="form-group">
                                   <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                                   <input type="text" className="form-control" value={this.state.kabupaten} disabled/>
                                 </div>
                               </div>

                               {/** Kecamatan **/}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Kecamatan<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={this.state.kecamatan} disabled/>
                                  </div>
                                </div>

                                {/** Kecamatan **/}
                                 <div className="col-md-6">
                                   <div className="form-group">
                                     <label>Kecamatan<span className="text-danger">*</span></label>
                                     <input type="text" className="form-control" value={this.state.kecamatan} disabled/>
                                   </div>
                                 </div>

                                 {/** Desa **/}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Desa<span className="text-danger">*</span></label>
                                      <input type="text" className="form-control" value={this.state.desa} disabled/>
                                    </div>
                                  </div>

                          </div>
                        </fieldset>

              <fieldset>
                <legend>Kode</legend>
                <div className="row">

                  <div className="col-12">
                    <div className="form-group">
                      <input type="text" className="form-control" value={this.state.kode} disabled/>
                    </div>
                  </div>


                </div>

              </fieldset>

              <fieldset>
                <legend>Data Tanah</legend>
                <div className="row">

                {/** Nama Bangunan **/}
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Nama<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama" name="nama" value={this.state.nama} onChange={this.handleChange} disabled/>
                  </div>
                </div>


                  {/** Nomor SK **/}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nomor SK</label>
                      <input type="text" className="form-control" placeholder="Nomor SK" name="nomor_sk" value={this.state.nomor_sk} onChange={this.handleChange} disabled/>
                    </div>
                  </div>


                {/** Tanggal SK **/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Tanggal SK</label>
                    <input type="text" className="form-control" placeholder="Tanggal SK" name="tanggal_sk" value={moment(this.state.tanggal_sk).format("D MMMM YYYY")} onChange={this.handleChange} disabled/>
                  </div>
                </div>

                {/** Luas Tanah (Meter) **/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Luas Tanah (Dalam Meter)<span className="text-danger">*</span></label>
                    <input type="number" className="form-control" placeholder="Luas Tanah (Dalam Meter)" name="luas_meter" value={this.state.luas_meter} onChange={this.handleChange} disabled/>
                  </div>
                </div>

              {/** Luas Tanah (Hektare) **/}
              <div className="col-md-6">
                <div className="form-group">
                  <label>Luas Tanah (Dalam Hektare)</label>
                  <input type="number" className="form-control" placeholder="Luas Tanah" name="luas_hektare" value={this.state.luas_hektare} onChange={this.handleChange} disabled/>
                </div>
              </div>

              {/** SK **/}
              <div className="col-md-6">
                <div className="form-group">
                <label>Dokumen SK</label>
                <br/>
                  {file_sk}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                <label>Dokumen Peserta Transmigrasi</label>
                <br/>
                  {file_peserta}
                </div>
              </div>

              </div>
            </fieldset>

            <div className="col-md-12">
              <fieldset>
                <legend>Koordinat</legend>
                <div className="col-md-12">


                  <Map
                    center={[this.state.lat, this.state.long]} zoom={this.state.zoom} id="leaflet-map-detail"
                  >

                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  {this.state.geojson_data ? (
                    <GeoJSON
                      key={`marker-01`}
                      data={this.state.geojson_data}
                      style={this.geoJSONStyle}
                      onEachFeature={this.onEachFeature}
                    />
                  ) :  ""}

                  </Map>
                </div>
              </fieldset>

            </div>

            <fieldset>
              <legend>Status</legend>
              {status}
            </fieldset>
            {publish_btn}

            <Link className="btn secondary-button" to="/admin/kawasan_khusus/lokasi_transmigrasi/list">Batal</Link>

            </form>


                      </div>
                  </div>



              </div>

          </div>
        );
    }

}
