import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import { Map as LeafletMap, GeoJSON, TileLayer, Marker, Popup } from 'react-leaflet';
import Auth from "../../../helper/Authentication";
const api_url = process.env.REACT_APP_API_URL;

export default class Detail extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = {
          id: this.props.match.params.id

         };

    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Peta";

        let id = document.getElementById("sidebarCollapse");
        document.getElementById("sidebar").classList.toggle("active");
        id.classList.toggle("active");

    }


    render() {
        let file_geojson = null;
        return (
            <div className="container-fluid">

                    <LeafletMap
                      center={[5.5530926,95.3043695]}
                      zoom={10}
                      maxZoom={100}
                      attributionControl={true}
                      zoomControl={true}
                      doubleClickZoom={true}
                      scrollWheelZoom={true}
                      dragging={true}
                      animate={true}
                      easeLinearity={0.35}
                    >
                    <GeoJSON
                        data={file_geojson}
                        style={() => ({
                          color: '#4a83ec',
                          weight: 0.5,
                          fillColor: "#1a1d62",
                          fillOpacity: 1,
                        })}
                      />
                      <TileLayer
                        url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                      />
                      <Marker position={[5.5530926,95.3043695]}>
                        <Popup>

                        </Popup>
                      </Marker>
                    </LeafletMap>


            </div>
        );
    }

}
