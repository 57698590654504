import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { message, notification } from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add_group extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group_name: '',
      description: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Group"
   }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });
  }

  handleSubmit(event) {
    event.preventDefault();

    message.loading('Loading...', 2.5);
    let form_data =
    {
      group_name: this.state.group_name,
      description: this.state.description
    };

    let header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.post( api_url+'acl/groups/add_group', form_data, header
    ).then(
      response => response.data,
      error => {
        alert(error)
      }
    )
    .then((data) => {
      if( data['status'] === 'success' ){

        notification.open({
          message: 'Berhasil',
          description:'Data telah disimpan ke database!',
        });

        this.props.history.push("/admin/acl/group/detail/"+data.data);

      }else{
        console.log( data )
        message.error( data['data'] );
      }

    })
  }


  render() {
    return (
      <div>
      <div className="col-12 grid-margin">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item primary-color">ACL</li>
            <Link className="breadcrumb-item" to="/admin/acl/group">Group</Link>
            <li className="breadcrumb-item active">Tambah Group</li>
          </ol>
        </nav>
      </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
            <h4 className="display-4 primary-color text-center">Tambah Group</h4>
            <br />

            <form onSubmit={this.handleSubmit}>
              <div className="row">
                {/* Input Nama Group */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Group<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Group" name="group_name" value={this.state.group_name} onChange={this.handleChange} required/>
                  </div>
                </div>

                {/* Input Deskripsi Group */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Deskripsi</label>
                    <input type="text" className="form-control" placeholder="Deskripsi" name="description" value={this.state.description} onChange={this.handleChange} required/>
                  </div>
                </div>

              </div>
              <button stype="submit" className="btn btn-full primary-button">Simpan</button>
            </form>

            </div>
          </div>
        </div>

      </div>
    );
  }

}
