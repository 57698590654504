import React, { Component } from "react";
import { Route } from 'react-router-dom';
import Moment from 'moment';
import { message } from 'antd';

import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";

import Dashboard from "./dashboard/Dashboard";
import Edit_profil from "./Edit_profil";

import Modules from "./acl/modules/Modules";
import Add_module from "./acl/modules/Add_module";
import Edit_module from "./acl/modules/Edit_module";
import Detail_module from "./acl/modules/Detail_module";
import Edit_submodule from "./acl/modules/Edit_submodule";

import Group from "./acl/group/Group";
import Add from "./acl/group/Add";
import Edit from "./acl/group/Edit";
import Group_Detail from "./acl/group/Detail";

import Level from "./acl/level/List_level";
import Add_level from "./acl/level/Add_level";
import Edit_level from "./acl/level/Edit_level";
import Hak_Akses from "./acl/level/Hak_akses";

import Password from "./acl/users/Password_users";

import Users_List from "./acl/users/List";
import Users_Add from "./acl/users/Add";
import Users_Edit from "./acl/users/Edit";


import Provinsi_List from "./data_wilayah/provinsi/List";
import Provinsi_Add from "./data_wilayah/provinsi/Add";
import Provinsi_Edit from "./data_wilayah/provinsi/Edit";

import Kabupaten_List from "./data_wilayah/kabupaten/List";
import Kabupaten_Add from "./data_wilayah/kabupaten/Add";
import Kabupaten_Edit from "./data_wilayah/kabupaten/Edit";

import Kecamatan_List from "./data_wilayah/kecamatan/List";
import Kecamatan_Add from "./data_wilayah/kecamatan/Add";
import Kecamatan_Edit from "./data_wilayah/kecamatan/Edit";

import Gampong_List from "./data_wilayah/gampong/List";
import Gampong_Add from "./data_wilayah/gampong/Add";
import Gampong_Edit from "./data_wilayah/gampong/Edit";

import BangunanDinas_List from "./pertanahan/bangunan/List";
import BangunanDinas_Add from "./pertanahan/bangunan/Add";
import BangunanDinas_Edit from "./pertanahan/bangunan/Edit";
import BangunanDinas_Detail from "./pertanahan/bangunan/Detail";
import BangunanDinas_Trash from "./pertanahan/bangunan/Trash";

import Kas_gampong_List from "./pertanahan/kas_gampong/List";
import Kas_gampong_Add from "./pertanahan/kas_gampong/Add";
import Kas_gampong_Edit from "./pertanahan/kas_gampong/Edit";
import Kas_gampong_Detail from "./pertanahan/kas_gampong/Detail";

import Persil_List from "./pertanahan/persil/List";
import Persil_Add from "./pertanahan/persil/Add";
import Persil_Edit from "./pertanahan/persil/Edit";
import Persil_Detail from "./pertanahan/persil/Detail";

import Tanah_wakaf_List from "./pertanahan/tanah_wakaf/List";
import Tanah_wakaf_Add from "./pertanahan/tanah_wakaf/Add";
import Tanah_wakaf_Edit from "./pertanahan/tanah_wakaf/Edit";
import Tanah_wakaf_Detail from "./pertanahan/persil/Detail";

/*
    Batas Wilayah
 */
import Wilayah_administrasi_List from "./batas_wilayah/wilayah_administrasi/List";
import Wilayah_administrasi_Add from "./batas_wilayah/wilayah_administrasi/Add";
import Wilayah_administrasi_Edit from "./batas_wilayah/wilayah_administrasi/Edit";
import Wilayah_administrasi_Detail from "./batas_wilayah/wilayah_administrasi/Detail";

import Wilayah_BP_List from "./batas_wilayah/batas_provinsi/List";
import Wilayah_BP_Add from "./batas_wilayah/batas_provinsi/Add";
import Wilayah_BP_Edit from "./batas_wilayah/batas_provinsi/Edit";
import Wilayah_BP_Detail from "./batas_wilayah/batas_provinsi/Detail";
import Wilayah_BP_Trash from "./batas_wilayah/batas_provinsi/Trash";

import Wilayah_BK_List from "./batas_wilayah/batas_kabupaten/List";
import Wilayah_BK_Add from "./batas_wilayah/batas_kabupaten/Add";
import Wilayah_BK_Edit from "./batas_wilayah/batas_kabupaten/Edit";
import Wilayah_BK_Detail from "./batas_wilayah/batas_kabupaten/Detail";
import Wilayah_BK_Trash from "./batas_wilayah/batas_kabupaten/Trash";

import Wilayah_BKc_List from "./batas_wilayah/batas_kecamatan/List";
import Wilayah_BKc_Add from "./batas_wilayah/batas_kecamatan/Add";
import Wilayah_BKc_Edit from "./batas_wilayah/batas_kecamatan/Edit";
import Wilayah_BKc_Detail from "./batas_wilayah/batas_kecamatan/Detail";

/*
    End Batas Wilayah
 */

import Peta_Detail from "./peta/lihat/Detail";

import Kawasan_hutan_List from "./kawasan_hutan/List";
import Kawasan_hutan_Add from "./kawasan_hutan/Add";
import Kawasan_hutan_Edit from "./kawasan_hutan/Edit";
import Kawasan_hutan_Detail from "./kawasan_hutan/Detail";
import Kawasan_hutan_Trash from "./kawasan_hutan/Trash";

import Hgu_List from "./hgu/List";
import Hgu_Add from "./hgu/Add";
import Hgu_Edit from "./hgu/Edit";
import Hgu_Detail from "./hgu/Detail";
import Hgu_Trash from "./hgu/Trash";

import Iup_List from "./iup/List";
import Iup_Add from "./iup/Add";
import Iup_Edit from "./iup/Edit";
import Iup_Detail from "./iup/Detail";
import Iup_Trash from "./iup/Trash";

import Transmigrasi_List from "./kawasan_khusus/lokasi_transmigrasi/List";
import Transmigrasi_Add from "./kawasan_khusus/lokasi_transmigrasi/Add";
import Transmigrasi_Edit from "./kawasan_khusus/lokasi_transmigrasi/Edit";
import Transmigrasi_Detail from "./kawasan_khusus/lokasi_transmigrasi/Detail";
import Transmigrasi_Trash from "./kawasan_khusus/lokasi_transmigrasi/Trash";

//##ImportComponent##//


class Admin extends Component {

    constructor(props) {
        super(props);

        if( !localStorage.getItem(process.env.REACT_APP_TOKEN) ){
            this.props.history.push("/login");
        }

        // token expired check
        const date    = new Date();
        const now     = date.getTime();
        const expired = Moment( localStorage.getItem('token_expired') ).toDate().getTime();


        if( now > expired ){
            this.props.history.push("/logout");
            message.error( "Token expired" );
        }

    }

    render() {
        return (
            <div>
                <Header />

                {/* container-fluid */}
                <div  className="container-fluid page-body-wrapper">
                    <Sidebar />

                    {/* main-panel */}
                    <div className="main-panel">

                        <div className="content-wrapper">
                            <Route exact path="/admin/dashboard" component={Dashboard} />
                            <Route exact path="/admin/edit_profil" component={Edit_profil} />

                            {/* Modules */}
                            <Route exact path="/admin/acl/modules" component={Modules} />
                            <Route exact path="/admin/acl/modules/add_module" component={Add_module} />
                            <Route exact path="/admin/acl/modules/detail_module/:id" component={Detail_module} />
                            <Route exact path="/admin/acl/modules/edit_module/:id" component={Edit_module} />
                            <Route exact path="/admin/acl/modules/edit_submodule/:id/:submodule_id" component={Edit_submodule} />

                            {/* Group */}
                            <Route exact path="/admin/acl/group" component={Group} />
                            <Route exact path="/admin/acl/group/add" component={Add} />
                            <Route exact path="/admin/acl/group/edit/:id" component={Edit} />
                            <Route exact path="/admin/acl/group/detail/:id" component={Group_Detail} />

                            {/* Level */}
                            <Route exact path="/admin/acl/level" component={Level} />
                            <Route exact path="/admin/acl/level/add_level" component={Add_level} />
                            <Route exact path="/admin/acl/level/edit_level/:id" component={Edit_level} />
                            <Route exact path="/admin/acl/level/hak_akses/:id" component={Hak_Akses} />


                            <Route exact path="/admin/acl/users/List" component={Users_List} />
                            <Route exact path="/admin/acl/users/add" component={Users_Add} />
                            <Route exact path="/admin/acl/users/edit/:id" component={Users_Edit} />

                            <Route exact path="/admin/data_wilayah/provinsi/List" component={Provinsi_List} />
                            <Route exact path="/admin/data_wilayah/provinsi/add" component={Provinsi_Add} />
                            <Route exact path="/admin/data_wilayah/provinsi/edit/:id" component={Provinsi_Edit} />

                            <Route exact path="/admin/data_wilayah/kabupaten/List" component={Kabupaten_List} />
                            <Route exact path="/admin/data_wilayah/kabupaten/add" component={Kabupaten_Add} />
                            <Route exact path="/admin/data_wilayah/kabupaten/edit/:id" component={Kabupaten_Edit} />

                            <Route exact path="/admin/data_wilayah/kecamatan/List" component={Kecamatan_List} />
                            <Route exact path="/admin/data_wilayah/kecamatan/add" component={Kecamatan_Add} />
                            <Route exact path="/admin/data_wilayah/kecamatan/edit/:id" component={Kecamatan_Edit} />

                            <Route exact path="/admin/data_wilayah/gampong/List" component={Gampong_List} />
                            <Route exact path="/admin/data_wilayah/gampong/add" component={Gampong_Add} />
                            <Route exact path="/admin/data_wilayah/gampong/edit/:id" component={Gampong_Edit} />

                            <Route exact path="/admin/pertanahan/bangunan/List" component={BangunanDinas_List} />
                            <Route exact path="/admin/pertanahan/bangunan/add" component={BangunanDinas_Add} />
                            <Route exact path="/admin/pertanahan/bangunan/edit/:id" component={BangunanDinas_Edit} />
                            <Route exact path="/admin/pertanahan/bangunan/detail/:id" component={BangunanDinas_Detail} />
                            <Route exact path="/admin/pertanahan/bangunan/trash" component={BangunanDinas_Trash} />

                            <Route exact path="/admin/pertanahan/tanah_kas_gampong/List" component={Kas_gampong_List} />
                            <Route exact path="/admin/pertanahan/tanah_kas_gampong/add" component={Kas_gampong_Add} />
                            <Route exact path="/admin/pertanahan/tanah_kas_gampong/edit/:id" component={Kas_gampong_Edit} />
                            <Route exact path="/admin/pertanahan/tanah_kas_gampong/detail/:id" component={Kas_gampong_Detail} />

                            <Route exact path="/admin/pertanahan/tanah_persil_masyarakat/List" component={Persil_List} />
                            <Route exact path="/admin/pertanahan/tanah_persil_masyarakat/add" component={Persil_Add} />
                            <Route exact path="/admin/pertanahan/tanah_persil_masyarakat/edit/:id" component={Persil_Edit} />
                            <Route exact path="/admin/pertanahan/tanah_persil_masyarakat/detail/:id" component={Persil_Detail} />

                            <Route exact path="/admin/pertanahan/tanah_wakaf/List" component={Tanah_wakaf_List} />
                            <Route exact path="/admin/pertanahan/tanah_wakaf/add" component={Tanah_wakaf_Add} />
                            <Route exact path="/admin/pertanahan/tanah_wakaf/edit/:id" component={Tanah_wakaf_Edit} />
                            <Route exact path="/admin/pertanahan/tanah_wakaf/detail/:id" component={Tanah_wakaf_Detail} />

                            <Route exact path="/admin/batas_wilayah/wilayah_administrasi/List" component={Wilayah_administrasi_List} />
                            <Route exact path="/admin/batas_wilayah/wilayah_administrasi/add" component={Wilayah_administrasi_Add} />
                            <Route exact path="/admin/batas_wilayah/wilayah_administrasi/edit/:id" component={Wilayah_administrasi_Edit} />
                            <Route exact path="/admin/batas_wilayah/wilayah_administrasi/detail/:id" component={Wilayah_administrasi_Detail} />

                            {/* Batas Wilayah -- Provinsi */}
                            <Route exact path="/admin/batas_wilayah/batas_provinsi/List" component={Wilayah_BP_List} />
                            <Route exact path="/admin/batas_wilayah/batas_provinsi/add" component={Wilayah_BP_Add} />
                            <Route exact path="/admin/batas_wilayah/batas_provinsi/edit/:id" component={Wilayah_BP_Edit} />
                            <Route exact path="/admin/batas_wilayah/batas_provinsi/detail/:id" component={Wilayah_BP_Detail} />
                            <Route exact path="/admin/batas_wilayah/batas_provinsi/trash" component={Wilayah_BP_Trash} />
                            {/* END Batas Wilayah -- Provinsi */}

                            {/* Batas Wilayah -- Kabupaten/Kota */}
                            <Route exact path="/admin/batas_wilayah/batas_kabupaten/List" component={Wilayah_BK_List} />
                            <Route exact path="/admin/batas_wilayah/batas_kabupaten/add" component={Wilayah_BK_Add} />
                            <Route exact path="/admin/batas_wilayah/batas_kabupaten/edit/:id" component={Wilayah_BK_Edit} />
                            <Route exact path="/admin/batas_wilayah/batas_kabupaten/detail/:id" component={Wilayah_BK_Detail} />
                            <Route exact path="/admin/batas_wilayah/batas_kabupaten/trash/" component={Wilayah_BK_Trash} />
                            {/* END Batas Wilayah -- Kabupaten/Kota */}

                            {/* Batas Wilayah -- Kecamatan */}
                            <Route exact path="/admin/batas_wilayah/batas_kecamatan/List" component={Wilayah_BKc_List} />
                            <Route exact path="/admin/batas_wilayah/batas_kecamatan/add" component={Wilayah_BKc_Add} />
                            <Route exact path="/admin/batas_wilayah/batas_kecamatan/edit/:id" component={Wilayah_BKc_Edit} />
                            <Route exact path="/admin/batas_wilayah/batas_kecamatan/detail/:id" component={Wilayah_BKc_Detail} />
                            {/* END Batas Wilayah -- Kecamatan */}

                            <Route exact path="/admin/peta/lihat/detail/" component={Peta_Detail} />

                            <Route exact path="/admin/kawasan_hutan/List" component={Kawasan_hutan_List} />
                            <Route exact path="/admin/kawasan_hutan/add" component={Kawasan_hutan_Add} />
                            <Route exact path="/admin/kawasan_hutan/edit/:id" component={Kawasan_hutan_Edit} />
                            <Route exact path="/admin/kawasan_hutan/detail/:id" component={Kawasan_hutan_Detail} />
                            <Route exact path="/admin/kawasan_hutan/trash" component={Kawasan_hutan_Trash} />

                            <Route exact path="/admin/perizinan/hgu/List" component={Hgu_List} />
                            <Route exact path="/admin/perizinan/hgu/add" component={Hgu_Add} />
                            <Route exact path="/admin/perizinan/hgu/edit/:id" component={Hgu_Edit} />
                            <Route exact path="/admin/perizinan/hgu/detail/:id" component={Hgu_Detail} />
                            <Route exact path="/admin/perizinan/hgu/trash" component={Hgu_Trash} />

                            <Route exact path="/admin/perizinan/iup/List" component={Iup_List} />
                            <Route exact path="/admin/perizinan/iup/add" component={Iup_Add} />
                            <Route exact path="/admin/perizinan/iup/edit/:id" component={Iup_Edit} />
                            <Route exact path="/admin/perizinan/iup/detail/:id" component={Iup_Detail} />
                            <Route exact path="/admin/perizinan/iup/trash" component={Iup_Trash} />

                            <Route exact path="/admin/kawasan_khusus/lokasi_transmigrasi/List" component={Transmigrasi_List} />
                            <Route exact path="/admin/kawasan_khusus/lokasi_transmigrasi/add" component={Transmigrasi_Add} />
                            <Route exact path="/admin/kawasan_khusus/lokasi_transmigrasi/edit/:id" component={Transmigrasi_Edit} />
                            <Route exact path="/admin/kawasan_khusus/lokasi_transmigrasi/detail/:id" component={Transmigrasi_Detail} />
                            <Route exact path="/admin/kawasan_khusus/lokasi_transmigrasi/trash" component={Transmigrasi_Trash} />

                            {/*##route##*/}

                        </div>

                        { /* Footer component */ }
                        <Footer />

                    </div>
                    {/* ./main-panel */}

                </div>
                {/* ./container-fluid */}

            </div>

        )
    }
}

export default Admin;
