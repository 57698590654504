import * as React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import { Map as LeafletMap, GeoJSON, TileLayer, Marker, Popup } from 'react-leaflet';
import Auth from "../../../helper/Authentication";
import "./leaflet.css";
import layers from './images/layers.png';

const api_url = process.env.REACT_APP_API_URL;
const jenis_tanah_list = [
  {
    kode        : "02",
    name        : "Batas Provinsi",
    url         : api_url+"batas_wilayah/batas_provinsi/search/",
    url_single  : api_url+"batas_wilayah/batas_provinsi/get/",
    warna       : "#cecece",
    border      : "#FBC02D"
  },
  {
    kode        : "0201",
    name        : "Batas Kabupaten/Kota",
    url         : api_url+"batas_wilayah/batas_kabupaten/search/",
    url_single  : api_url+"batas_wilayah/batas_kabupaten/get/",
    warna       : "#77a4aa",
    border      : "#FBC02D"
  },
  {
    kode        : "17",
    name        : "Tanah Bangunan Kantor Pemerintah",
    url         : api_url+"pertanahan/bangunan/search/",
    url_single  : api_url+"pertanahan/bangunan/get/",
    warna       : "#00c823",
    border      : "#FBC02D"
  },
  {
    kode        : "03",
    name        : "Kawasan Hutan",
    url         : api_url+"kawasan_hutan/search/",
    url_single  : api_url+"kawasan_hutan/get/",
    warna       : "#03ad00",
    border      : "#FBC02D"
  },
  {
    kode        : "13",
    name        : "Hak guna Usaha",
    url         : api_url+"hak_guna_usaha/search/",
    url_single  : api_url+"pertanahan/bangunan/get/",
    warna       : "#3087cc",
    border      : "#FBC02D"
  },
  {
    kode        : "15",
    name        : "Izin Usaha Pertambangan",
    url         : api_url+"izin_usaha_pertambangan/search/",
    url_single  : api_url+"izin_usaha_pertambangan/get/",
    warna       : "#a70300",
    border      : "#FBC02D"
  },
  {
    kode        : "16",
    name        : "Lokasi Transmigrasi",
    url         : api_url+"lokasi_transmigrasi/search/",
    url_single  : api_url+"lokasi_transmigrasi/get/",
    warna       : "#728ea7",
    border      : "#FBC02D"
  }
];

export default class Full extends React.Component {
    constructor(props) {
        super(props);
        // Auth.is_access( window.location.pathname )

        if( !localStorage.getItem(process.env.REACT_APP_TOKEN) ){
          this.props.history.push("/login");
        }

        this.state = {
          id: this.props.match.params.id,
          control_layer: 0,
          layer_opt: "google_street",
          lat: 5.5646816,
          lng: 95.3370112,
          zoom: 18,
          sidebar_content_collapse: true,
          url_search: "",
          kode_pertanahan: "",
          keyword: "",
          search_result: "",
          search_error: "",
          map_cart: [],
          geojson_arr: [],
          modalShow: false,
          active_data_id: "",
          active_kode_tanah: "",
          data_tanah_detail: null

         };

         this.handleChange = this.handleChange.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);
         // this.geoJSONStyle = this.geoJSONStyle.bind(this);
    }

    componentDidMount() {
        document.title = process.env.REACT_APP_APP_NAME + "::Peta";
        this.get_data()
        this.centering_map()

        // Menampilkan marker
        // const L = require("leaflet");
        // delete L.Icon.Default.prototype._getIconUrl;
        // L.Icon.Default.mergeOptions({
        //   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        //   iconUrl: require("leaflet/dist/images/marker-icon.png"),
        //   shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        // });
    }



    centering_map(){
      // centering map
      let count = this.state.geojson_arr.length;
      if( count > 0 ){
        let arr = this.state.geojson_arr[count-1].data;

        if( arr.features ){
            if( arr.features[0].geometry.type == "MultiPolygon"){
              this.setState({
                lat: arr.features[0].geometry.coordinates[0][0][1][1],
                lng: arr.features[0].geometry.coordinates[0][0][0][0],
                zoom: 8
              });
            }else if ( arr.features[0].geometry.type == "Polygon" ) {
              this.setState({
                lat: arr.features[0].geometry.coordinates[0][0][1],
                lng: arr.features[0].geometry.coordinates[0][0][0],
                zoom: 13
              });
            }
        }else if ( arr.geometry  ) {
            if( arr.geometry.type == "MultiPolygon"){
              this.setState({
                lat: arr.geometry.coordinates[0][0][1][1],
                lng: arr.geometry.coordinates[0][0][0][0],
                zoom: 8
              });
            }else if ( arr.geometry.type == "Polygon" ) {
              this.setState({
                lat: arr.geometry.coordinates[0][0][1],
                lng: arr.geometry.coordinates[0][0][0],
                zoom: 13
              });
            }
        }




      }
    }


   async get_data(){
     if( this.state.map_cart.length != 0 ){
       let cart = this.state.map_cart;

       try {
         let res = [];

         for( let x in cart ){
           res[x] = await axios.get( api_url+cart[x].file_geojson );
           res[x].data._id = cart[x].id;
           res[x].data.kode_pertanahan = cart[x].kode_pertanahan;
         }

         this.setState({
           geojson_arr: res
         });

       } catch (error) {
         console.error(error);
       }

     }

   }

    show_control_list(){
      if( this.state.control_layer === 0 ){
        this.setState({
          control_layer: 1
        }, () => {
          document.getElementById("leaflet-control-layers-list").style.display = "inline";
        });
      }else{

        this.setState({
          control_layer: 0
        }, () => {
          document.getElementById("leaflet-control-layers-list").style.display = "none";
        });
      }
    }

    show_sidebar_content(){
      if( this.state.sidebar_content_collapse === true ){
        this.setState({sidebar_content_collapse: false});
      }else{
        this.setState({ sidebar_content_collapse: true});
      }
    }

    handleChange(event){
      const target = event.target;
      const value = target.type === 'checkbox'
          ? target.checked
          : target.value;
      const name = target.name;


      this.setState({[name]: value});
    }

    handleSubmit(event) {
      event.preventDefault();
      document.getElementById("loader").style.display = "inline";

      if( this.state.kode_pertanahan ){
        // find search url
        let data_tanah  = jenis_tanah_list.find(x => x.kode === this.state.kode_pertanahan);

        let header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.get( data_tanah.url+'?keyword='+this.state.keyword, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            data.kode_pertanahan = this.state.kode_pertanahan;

            this.setState({
              search_result: data
            });

          }
        })
      }else{
        notification.open({
            message: 'Error',
            description: "Silahkan pilih jenis tanah",
            icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
          });
      }
      document.getElementById("loader").style.display = "none";

    }

    select_jenis_tanah(){
      if( jenis_tanah_list !== undefined && typeof jenis_tanah_list == "object"){
        if( jenis_tanah_list.length > 0 ){

          let res = jenis_tanah_list.map((value, index) => {
            return(
              <option value={value.kode} key={ Math.random() } selected= {(value.kode == this.state.kode_pertanahan)?true:false}>{value.name}</option>
            )

          });

          return(
            <select name="kode_pertanahan" className="form-control" onChange={this.handleChange} required>
            <option value="" key={ Math.random() }>Pilih</option>
            {res}
            </select>
          );

        }

      }
    }

    search_result(){
      const data = this.state.search_result;

      if( typeof data == "object" && data != ""){
        if( data.status == "success" ){
          const res = data.data.map((value, index) => {
            let kabupaten = (value.kabupaten)?value.kabupaten[0].kabupaten:"";
            // let kecamatan = (value.kecamatan)?value.kecamatan[0].kecamatan:"";

            let kabupaten_arr = [];
            kabupaten_arr.push(<p className="mb-1">{kabupaten}</p>);

            let kecamatan_arr = [];
            if( value.kecamatan ){
              kecamatan_arr.push(<small>KECAMATAN: {value.kecamatan[0].kecamatan}</small>);
            }

            let slice = ( value.kode )?" | ":"";

            return(
              <div className="list-group pt-2">
                <button className="list-group-item list-group-item-action flex-column align-items-start" onClick={() => this.set_map_cart(value._id, value.nama, value.file_geojson, data.kode_pertanahan)}>
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{value.nama}</h5>
                  </div>
                  <small>{value.kode}</small> {slice}
                  <small>Luas: {new Intl.NumberFormat('id-ID').format(value.luas)} M<sup>2</sup></small>
                </button>
              </div>
            )

          });

          return res;

        }else{
          if( data.data === "Token tidak valid" ){
            this.props.history.push("/login");
          }

          return(<div className="text-danger">{data.data}</div>);
        }
      }
    }

    set_map_cart(id, nama, file_geojson, kode_pertanahan){
      let data = {
          // id      : Math.random(),
          id      : id,
          nama    : nama,
          file_geojson      : file_geojson,
          kode_pertanahan: kode_pertanahan
      }

      if( data.id !== "" && data.nama !== "" && data.file_geojson ){
        let cart = this.state.map_cart;
        cart.push( data )
        this.setState({map_cart: cart}, () =>{
          this.componentDidMount()
        });
      }

    }

    generate_map_layer(){
      let geojson = this.state.geojson_arr;
      if( geojson.length > 0 ){

        const res = geojson.map((value, index) => {
          // styling layer
          let data_tanah  = jenis_tanah_list.find(x => x.kode === value.data.kode_pertanahan );
          let style = {
            color: data_tanah.border,
            weight: 1,
            fillOpacity: 0.5,
            fillColor: data_tanah.warna,
          }

          return(
            <GeoJSON
            key={"map_"+index}
            data={value.data}
            style={style}
            onEachFeature={ this.onEachFeature.bind(this) }
            />)
        });
        return res;
      }
    }

    onEachFeature(feature: Object, layer: Object) {
      layer.on({
        // mouseover: this.mouseover.bind(this),
        // mouseout: this.resetHighlight.bind(this),
        click: this.clickToFeature.bind(this)

      });
    }

    mouseover(e){
      let position = [[97.24810000000014,4.1735]]

      return(
        <Marker key={`marker-111`} position={position}>
          <Popup>
            <span>Popup</span>
          </Popup>
        </Marker>
      )
    }

    clickToFeature(e) {
       let layer = e.target.feature.properties;
       let id    = e.target.options.data._id;
       let kode_pertanahan = e.target.options.data.kode_pertanahan;

       this.setState({
         active_data_id: id,
         active_kode_tanah: kode_pertanahan
       }, () =>{
         // get single record
         const kode_pertanahan = this.state.active_kode_tanah;
         const find = jenis_tanah_list.find(x => x.kode === kode_pertanahan);

         if( find != undefined ){
           let header = {
             headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
           };

         axios.get( find.url_single+this.state.active_data_id, header)
           .then(
             response => response.data,
             error => { alert( error ) }
           )
           .then((data) => {

             if( typeof data == 'object' ){
               data.data.jenis = find.name;
               this.setState({
                 data_tanah_detail: data.data,
                 modalShow: true,
               }, () =>{
                 this.show_sidebar_content()
               });
             }

           })

         }

       });

    }

    modal_content(){
      let data_tanah = this.state.data_tanah_detail;

      if( typeof data_tanah == "object" && data_tanah ){

        return(
          <table className="table table-sm">
            <tr>
              <td>Jenis</td>
              <td>:</td>
              <td>{data_tanah.jenis}</td>
            </tr>
            <tr>
              <td>Kode</td>
              <td>:</td>
              <td>{data_tanah.kode}</td>
            </tr>
            <tr>
              <td>Nama</td>
              <td>:</td>
              <td>{data_tanah.nama}</td>
            </tr>
            <tr>
              <td>Kabupaten/Kota</td>
              <td>:</td>
              <td>{(data_tanah.kabupaten)?data_tanah.kabupaten[0].kabupaten:"-"}</td>
            </tr>
            <tr>
              <td>Kecamatan</td>
              <td>:</td>
              <td>{(data_tanah.kecamatan)?data_tanah.kecamatan[0].kecamatan:"-"}</td>
            </tr>
            <tr>
              <td>Luas</td>
              <td>:</td>
              <td>{new Intl.NumberFormat('id-ID').format(data_tanah.luas)} Meter<sup>2</sup> ({new Intl.NumberFormat('id-ID').format((data_tanah.luas/10000))} Hektare)</td>
            </tr>
          </table>
        )
      }

      // return(<div>{this.state.active_data_id}</div>)
    }

    close_modal(){
      this.setState({
        modalShow: false
      }, () =>{
        this.show_sidebar_content()
      });
    }

    render() {

        let file_geojson = null;
        let tile_url     = null;

        if( this.state.layer_opt === "google_terrain"){
          tile_url = "http://www.google.com/maps/vt?lyrs=p@189&gl=cn&x={x}&y={y}&z={z}";
        }else if ( this.state.layer_opt === "google_street") {
          tile_url = "http://www.google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}";
        }else if ( this.state.layer_opt === "google_satelit") {
          tile_url = "http://www.google.com/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}";
        }else{
          tile_url = "http://{s}.tile.osm.org/{z}/{x}/{y}.png";
        }

        let sidebar_content_collapse = '';
        if( this.state.sidebar_content_collapse ){
          sidebar_content_collapse = "leaflet-sidebar leaflet-control leaflet-sidebar-left collapsed";
        }else{
          sidebar_content_collapse = "leaflet-sidebar leaflet-control leaflet-sidebar-left";
        }


        return (
          <div>
          <LeafletMap
            id="leaflet-map-full"
            center={[this.state.lat, this.state.lng]}
            zoom={this.state.zoom}
            attributionControl={true}
            zoomControl={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            dragging={true}
            animate={true}
            easeLinearity={0.35}
          >

          {this.generate_map_layer()}


            <TileLayer
              url={tile_url}
            />
            <Marker
              position={[this.state.lat, this.state.lng]}
              zIndexOffset={999999999}
            >
            {/*
              <Popup className="modal">
              </Popup>
              */}

              <Modal
                title="Detail"
                visible={this.state.modalShow}
                onOk={ () => this.close_modal() }
                onCancel={ () => this.close_modal() }
              >
              {this.modal_content()}
              </Modal>



            </Marker>
          </LeafletMap>

          {/** Layer Option */}
          <div className="leaflet-top leaflet-right">
            <form onSubmit={this.handleSubmit} id="MapForm">
              <div className="leaflet-control-layers leaflet-control leaflet-control-layers-expanded">

                <a className="leaflet-control-toggle" onClick={() => this.show_control_list()}>
                  <img src={layers} className="mx-auto d-block"/>
                </a>

                <section className="leaflet-control-layers-list" id="leaflet-control-layers-list">
                  <br/>
                  <div className="leaflet-control-layers-base">

                    <label>
                      <div>
                        <input type="radio" className="leaflet-control-layers-selector" name="layer_opt" value="osm" checked={ "osm" === this.state.layer_opt} onChange={this.handleChange}/>
                        <span> OSM</span>
                      </div>
                    </label>

                    <label>
                      <div>
                        <input type="radio" className="leaflet-control-layers-selector" name="layer_opt" value="google_street" checked={ "google_street" === this.state.layer_opt} onChange={this.handleChange}/>
                        <span> Google Street</span>
                      </div>
                    </label>

                    <label>
                      <div>
                        <input type="radio" className="leaflet-control-layers-selector" name="layer_opt" value="google_satelit" checked={ "google_satelit" === this.state.layer_opt} onChange={this.handleChange}/>
                        <span> Google Satelit</span>
                      </div>
                    </label>

                    <label>
                      <div>
                        <input type="radio" className="leaflet-control-layers-selector" name="layer_opt" value="google_terrain" checked={ "google_terrain" === this.state.layer_opt} onChange={this.handleChange}/>
                        <span> Google Terrain</span>
                      </div>
                    </label>

                    <div className="leaflet-control-layers-overlays"></div>
                  </div>
                </section>
              </div>
            </form>

          </div>


          <div id="sidebar" className={sidebar_content_collapse}>
                  {/** nav tabs **/}
                  <div className="leaflet-sidebar-tabs">
                      {/** top aligned tabs **/}
                      <ul role="tablist">
                          <li className=""><a role="tab" onClick={() => this.show_sidebar_content()}><i className="fa fa-bars active"></i></a></li>
                      </ul>

                      {/** bottom aligned tabs **/}
                      <ul role="tablist">
                          <li>
                            <Link to="/admin/dashboard/"><i className="fas fa-tachometer-alt"></i></Link>
          								</li>
                      </ul>
                  </div>

                  {/** panel content **/}
                  <div className="leaflet-sidebar-content">
                      <div className="leaflet-sidebar-pane active">
                          <h1 className="leaflet-sidebar-header">
                              {process.env.REACT_APP_APP_NAME}
                              <span className="leaflet-sidebar-close" onClick={() => this.show_sidebar_content()}><i className="fa fa-caret-left"></i></span>
                          </h1>
          								<form onSubmit={this.handleSubmit} className="mt-4">

          									<div className="col-md-12">
                              <div className="form-group">
            										<input type="text" name="keyword" className="form-control" value={this.state.keyword} onChange={this.handleChange} placeholder="Masukkan Kata Kunci" autoComplete="off"/>
                              </div>
          									</div>


                            <div className="col-md-12">
                              <div className="form-group">
                  								{ this.select_jenis_tanah() }
                  						</div>
                            </div>

                            <div className="col-md-12">
                              <button className="btn primary-button" type="submit">Cari</button>
                            </div>

          								</form>


                          {/* Seacrh Result */}
                          <div>
                            <br/>

                            {this.search_result()}
                          </div>

                      </div>



                  </div>

              </div>
            </div>


        );
    }

}
