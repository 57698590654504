import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { message, notification, Icon } from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit_submodule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      module_id: this.props.match.params.id,
      module_name: '',
      submodule_id: this.props.match.params.submodule_id,
      submodule_name: "",
      submodule_url: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Edit Submodul";
     this.get_submodule();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });

  }

  handleSubmit(event) {
    event.preventDefault();

    message.loading('Loading...', 2.5);

  
    var form_data =
    {
      submodule_name: this.state.submodule_name,
      submodule_url: this.state.submodule_url
    };

    var header = {
      headers: {
        'api_token': localStorage.getItem('token') }
    };

    axios.put( api_url+'acl/modules/'+this.state.module_id+'/edit_submodule/'+this.state.submodule_id, form_data, header
    ).then(
      response => response.data,
      error => {
        alert(error)
      }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){

          notification.open({
            message: 'Berhasil',
            description:'Data telah tersimpan!',
          });

          this.props.history.push("/admin/acl/modules/detail_module/"+this.state.module_id);
          message.destroy();
        }else{
          notification.open({
            message: 'API Error',
            description: data.data,
            icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
          });
        }
      }
    })
  }

  get_submodule(){
    var header = {
      headers: {
        'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };


    axios.get( api_url+'acl/modules/'+this.state.module_id+'/get_submodules/'+this.state.submodule_id, header)
    .then(
      response => response.data,
      error => {
        alert(error)
      }
    )
    .then((data) => {
      if( typeof data == 'object' && data !== null){
          const submodule = data.data;

          this.setState({
            submodule_name: submodule.submodule_name,
            submodule_url: submodule.submodule_url,
          });

      }

    })
  }



  render() {
    return (
      <div>
        <div className="col-12 grid-margin">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">ACL</li>
              <Link className="breadcrumb-item" to="/admin/acl/modules">Modul</Link>
              <li className="breadcrumb-item active">Edit Sub Modul</li>
            </ol>
          </nav>
        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="display-4 primary-color text-center">Edit Sub Modul</h4>
              <br />

              <form onSubmit={this.handleSubmit}>
              <div className="row">

                {/* Input Nama Sub Modul */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Sub Modul<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Sub Modul" name="submodule_name" value={this.state.submodule_name} onChange={this.handleChange} required/>
                  </div>
                </div>

                {/* Input Sub Modul URL */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>URL Sub Modul<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="URL Sub Modul" name="submodule_url" value={this.state.submodule_url} onChange={this.handleChange} required/>
                  </div>
                </div>

                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
              </div>
              </form>

            </div>
          </div>
        </div>



      </div>
    );
  }

}
