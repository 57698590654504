import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { message, notification } from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add_modules extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      module_name: '',
      module_url: '',
      module_icon: '',
      active_module: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Modul"
   }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });
  }

  handleSubmit(event) {
    event.preventDefault();

    message.loading('Loading...', 2.5);

    var is_active = (this.state.active_module === "yes")?true:false;
    var form_data =
    {
      module_name: this.state.module_name,
      module_url: this.state.module_url,
      module_icon: this.state.module_icon,
      is_active: is_active
    };

    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.post( api_url+'acl/modules/add_module', form_data, header
    ).then(
      response => response.data,
      error => {
        alert(error)
      }
    )
    .then((data) => {
      if( data['status'] === 'success' ){

        notification.open({
          message: 'Berhasil',
          description:'Data telah disimpan ke database!',
        });

        this.props.history.push("/admin/acl/modules");

      }else{
        message.error( data['data'] );
      }

    })
  }


  render() {
    return (
      <div>
      <div className="col-12 grid-margin">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">ACL</li>
            <Link className="breadcrumb-item" to="/admin/acl/modules">Modul</Link>
            <li className="breadcrumb-item active">Tambah Modul</li>
          </ol>
        </nav>
      </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
            <h4 className="display-4 primary-color text-center">Tambah Modul</h4>
            <br />

            <form onSubmit={this.handleSubmit}>
              <div className="row">
                {/* Input Nama Modul */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Modul<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Modul" name="module_name" value={this.state.module_name} onChange={this.handleChange} required/>
                  </div>
                </div>

                {/* Input URL Modul */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>URL Modul<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="URL Modul" name="module_url" value={this.state.module_url} onChange={this.handleChange} required/>
                  </div>
                </div>

                {/* Input Icon Modul */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Icon Modul<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Font Awesome Icon" name="module_icon" value={this.state.module_icon} onChange={this.handleChange} required/>
                    <small className="soft-color">Ex: fas fa-users</small>
                  </div>
                </div>

                {/* Input Actif Modul */}
                <div className="col-md-6">
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <div className="form-radio">
                      <label className="form-check-label">
                      <input type="radio" className="form-check-input" name="active_module" value="yes" onChange={this.handleChange}/> Aktif <i className="input-helper"></i></label>
                      </div>
                    </div>

                  <div className="col-sm-5">
                    <div className="form-radio">
                      <label className="form-check-label">
                      <input type="radio" className="form-check-input" name="active_module" value="no" onChange={this.handleChange}/> Tidak Aktif <i className="input-helper"></i></label>
                    </div>
                  </div>
                  </div>
                </div>

              </div>
              <button stype="submit" className="btn btn-full primary-button">Simpan</button>
            </form>

            </div>
          </div>
        </div>

      </div>
    );
  }

}
