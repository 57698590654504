import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Admin from "./components/admin/Admin";
import Login from "./components/public/Login";
import Logout from "./components/public/Logout";
import Homepage from "./components/public/Homepage";
import Tentang from "./components/public/Tentang";
import Kontak from "./components/public/Kontak";
import Page from "./components/public/Page";
import Peta_Full from "./components/admin/peta/lihat/Full";
import Notfound from "./components/public/Notfound";
class App extends Component {
  render() {

    return (
      <Router basename ="/" >

        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/tentang"  component={Tentang} />
          <Route path="/kontak"  component={Kontak} />
          <Route path="/page/:id"  exact component={Page} />
          <Route path="/login"  exact component={Login} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/admin" component={Admin} />

          <Route path="/admin" component={Admin} />

          {/* Peta Full */}
          <Route exact path="/peta" component={Peta_Full} />

          <Route path='*' component={Notfound} />
        </Switch>


      </Router>

    );
  }
}
export default App;
