import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          nik:'',
          username:'',
          nama:'',
          tempat_lahir:'',
          tanggal_lahir:new Date(),
          jenis_kelamin:'',
          jabatan:'',
          alamat:'',
          phone:'',
          email: '',
          sk:'',
          is_verifikator: false,
          wilayah_tugas:'',
          organisasi_id: '',
          password:'',
          password2: '',
          group_id:'',
          group_list: '',
          org_list: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Users";

        this.get_group_list()
        this.get_org_list()

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    onChangeFile(e) {
      this.setState({sk:e.target.files[0]})
    }

    tanggal_lahir = date => {
      this.setState({
        tanggal_lahir: date
      });
    };

    handleSubmit(event) {
        event.preventDefault();
        document.getElementById("loader").style.display = "inline";

        let formData = new FormData();
        formData.append('nik',this.state.nik);
        formData.append('username',this.state.username);
        formData.append('email',this.state.email);
        formData.append('nama',this.state.nama);
        formData.append('tempat_lahir',this.state.tempat_lahir);
        formData.append('tanggal_lahir',this.state.tanggal_lahir);
        formData.append('jenis_kelamin',this.state.jenis_kelamin);
        formData.append('jabatan',this.state.jabatan);
        formData.append('alamat',this.state.alamat);
        formData.append('phone',this.state.phone);
        formData.append('sk',this.state.sk);
        formData.append('opd_id',this.state.organisasi_id);
        formData.append('password',this.state.password);
        formData.append('group_id',this.state.group_id);

        if( this.state.is_verifikator == true ){
          formData.append('is_verifikator', true);
        }

        let header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        let msg = "";
        if( this.state.password != this.state.password2 ){
          msg = "Password tidak sama dengan verifikasi password";
          notification.open({
            message: 'Error',
            description: msg,
            icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />
          });
        }else{
          post(api_url+'acl/users/add', formData, header).then((data)=>{
              if( typeof data == 'object' ){
                if( data.data.status == 'success' ){

                  notification.open({
                    message: 'Berhasil',
                    description:'Data telah disimpan ke database!',
                  });

                  this.setState({loading: false});

                  this.props.history.push("/admin/acl/users/list");

                }else{
                  notification.open({
                      message: 'Error',
                      description: data.data.data,
                      icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                    });
                    this.setState({loading: false});
                }
              }
          })
        }
        document.getElementById("loader").style.display = "none";

      }

    get_wilayah_tugas_list(){
      axios.get(api_url+"acl/groups/get_groups").then(
          response => {
            this.setState({ group_list: response.data.data});
        },
        error => {
          alert(error)
        }

      );
    }

   select_option_for_wilayah_tugas(){
     if( this.state.wilayah_tugas_list !== undefined && typeof this.state.wilayah_tugas_list == "object"){
       if( this.state.wilayah_tugas_list.length > 0 ){
         const res = (
             this.state.wilayah_tugas_list.map(( value ) =>
               <option value={value._id} key={ Math.random() } selected= {(value._id == this.state._id)?true:false}>{value.organisasi}</option>
             )
         );
         return res;
       }
     }
   }

   get_org_list(){
     axios.get(api_url+"organisasi/list_skpa").then(
         response => {
           this.setState({ org_list: response.data.data});
       },
       error => {
         alert(error)
       }

     );
   }

   select_option_for_org(){
     if( this.state.org_list !== undefined && typeof this.state.org_list == "object"){
       if( this.state.org_list.length > 0 ){
         const res = (
             this.state.org_list.map(( value ) =>
               <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.organisasi_id)?true:false}>{value.nama_opd}</option>
             )
         );

         return res;
       }
     }
   }

     get_group_list(){
       axios.get(api_url+"acl/groups/get_groups").then(
           response => {
             this.setState({ group_list: response.data.data});
         },
         error => {
           alert(error)
         }

       );
     }

    select_option_for_group(){
      if( this.state.group_list !== undefined && typeof this.state.group_list == "object"){
        if( this.state.group_list.length > 0 ){
          const res = (
              this.state.group_list.map(( value ) =>
                <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.group_id)?true:false}>{value.group_name}</option>
              )
          );
          return res;
        }
      }
    }

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <Link className="breadcrumb-item" to="/admin/acl/users/list">Users</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


				{/** NIK **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>NIK<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="NIK" name="nik" value={this.state.nik} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Username **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Username<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Username" name="username" value={this.state.username} onChange={this.handleChange} required/>
                  </div>
                </div>

        {/** Email **/}
        <div className="col-md-6">
          <div className="form-group">
            <label>Email<span className="text-danger">*</span></label>
            <input type="email" className="form-control" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChange} required/>
          </div>
        </div>


				{/** Nama **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                  </div>
                </div>


        				{/** Tempat Lahir **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Tempat Lahir</label>
                    <input type="text" className="form-control" placeholder="Tempat Lahir" name="tempat_lahir" value={this.state.tempat_lahir} onChange={this.handleChange} />
                  </div>
                </div>


        				{/** Tanggal Lahir **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Tanggal Lahir</label>
                    <div className="datepicker-container">
                      <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal_lahir} onChange={date => this.tanggal_lahir(date)} />
                    </div>
                  </div>
                </div>

                {/** Alamat **/}
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Alamat</label>
                    <input type="text" className="form-control" placeholder="Alamat" name="alamat" value={this.state.alamat} onChange={this.handleChange} />
                  </div>
                </div>


        				{/** Jabatan **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Jabatan<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Jabatan" name="jabatan" value={this.state.jabatan} onChange={this.handleChange} required/>
                  </div>
                </div>


        				{/** Nomor Telepon **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nomor Telepon</label>
                    <input type="text" className="form-control" placeholder="Nomor Telepon" name="phone" value={this.state.phone} onChange={this.handleChange} />
                  </div>
                </div>


                {/** Organisasi **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Organisasi<span className="text-danger">*</span></label>
                    <select name="organisasi_id" className="form-control" onChange={this.handleChange} required>
                    <option value="" key={ Math.random() }>Pilih</option>
                    { this.select_option_for_org() }
                    </select>
                  </div>
                </div>


        				{/** Group **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Grup<span className="text-danger">*</span></label>
                    <select name="group_id" className="form-control" onChange={this.handleChange} required>
                    <option value="" key={ Math.random() }>Pilih</option>
                    { this.select_option_for_group() }
                    </select>
                  </div>
                </div>

                {/** Password **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Password<span className="text-danger">*</span></label>
                    <input type="password" className="form-control" placeholder="Password" name="password" value={this.state.password} onChange={this.handleChange} required/>
                  </div>
                </div>

                {/** Password 2 **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Verifikasi Password<span className="text-danger">*</span></label>
                    <input type="password" className="form-control" placeholder="Password" name="password2" value={this.state.password2} onChange={this.handleChange} required/>
                  </div>
                </div>


                {/** Upload File  **/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload SK<span className="text-danger">*</span></label><br/>
                    <input type="file" className="form-control-file" onChange={this.onChangeFile} />
                    <small>Upload file dalam format pdf, ukuran maksimal 1 MB</small>
                  </div>
                </div>

                {/* Input Verifikator */}
                <div className="col-md-6">
                  <div className="form-group row">
                    <div class="form-check">
                      <input type="checkbox" name="is_verifikator" value={this.state.is_verifikator} onChange={this.handleChange} class="form-check-input" />
                      <label class="form-check-label">Akun Verifikator</label>
                    </div>
                  </div>
                </div>

        			{/*##form_input##*/}

                                </div>
                                <br/>
                                <button stype="submit" className="btn primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
