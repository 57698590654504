import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-fluid clearfix">
          <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Developed By &nbsp;
            <a href="http://diskominfo.acehprov.go.id/" target="_blank" rel="noopener noreferrer">DINAS KOMUNIKASI, INFORMATIKA DAN PERSANDIAN ACEH</a></span>

          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">v {process.env.REACT_APP_VERSION} &nbsp;
            <i className="mdi mdi-heart text-danger"></i> SIAT-DEV
          </span>
        </div>
      </footer>
  );
  }
}

export default Footer;
