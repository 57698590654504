import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import Auth from "../../../helper/Authentication";
const api_url = process.env.REACT_APP_API_URL;

export default class Detail extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = {
          id: this.props.match.params.id,
          group_name: '',
          description: '',
          module_data: null,
          modules_access: null,
          create: {},
          read: {},
          update: {},
          delete: {}
         };

         this.handleChange = this.handleChange.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        document.title = process.env.REACT_APP_APP_NAME + "::Group Detail";
        this.get_group_data();
        this.get_module_data();

        // loader
        document.getElementById("loader").style.display = "inline";
    }

    handleChange(event, module_id, submodule_id){
      event.preventDefault();
      const target = event.target;

      // alert( target.checked )
      // console.log( module_id, submodule_id, target.checked )

    }

    handleSubmit(event){
      // event.preventDefault();
      // let form = document.getElementById("formAccess");
      //
      // for(let i in form ){
      //
      //   if( form[i] != null ){
      //       console.log( form[i].name, form[i].checked );
      //   }
      // }


    }

    get_group_data(){
        document.getElementById("loader").style.display = "inline";
        let header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'acl/groups/get_groups/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {
          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                group_name: data.data.group_name,
                description: data.data.description,
                modules_access: data.data.modules
            }, () =>{
              // hide loader
              document.getElementById("loader").style.display = "none";

            });
            }else{
              message.error( data.data );
            }

          }
        })
    }

    get_module_data(){
      document.getElementById("loader").style.display = "inline";
      let header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      axios.get( api_url+'acl/modules/get_modules/', header)
      .then(
        response => response.data,
        error => { alert( error ) }
      )
      .then((data) => {
        if( typeof data == 'object' ){
            this.setState({
              module_data: data.data
            }, () =>{
              // hide loader
              document.getElementById("loader").style.display = "none";
            });
        }
      })
    }

    module_list(){
      let data = this.state.module_data;
      if( typeof data == 'object' && data != null ){
        let res = [];
        for( let x in data ){
          res.push(
            <tr className="module-name" key={Math.random()}>
              <td className="primary-color" colSpan="6"><strong>{data[x].module_name}</strong></td>
            </tr>
          );

          res.push( this.submodule_list( data[x]._id, data[x].submodules ) )
        }
        return res;
      }
    }

    submodule_list( module_id, submodules ){
      if( typeof submodules == 'object' && submodules != null ){
        let res = [];
        for( let x in submodules ){
          let find = this.find_submodules_by_id(module_id, submodules[x].submodule_id);
          let create, read, update, del;
          if( find != null ){
            create = find.create;
            read   = find.read;
            update = find.update;
            del = find.delete;
          }

          let nc = "create["+submodules[x].submodule_id+"]";
          let nr = "read["+submodules[x].submodule_id+"]";
          let nu = "update["+submodules[x].submodule_id+"]";
          let nd = "delete["+submodules[x].submodule_id+"]";

          res.push(
            <tr key={ Math.random() }>
              <td className="text-center">-</td>
              <td>{submodules[x].submodule_name}</td>
              <td className="text-center"><input type="checkbox" name={nc} value={create} onChange={(e) => this.handleChange(e, module_id, submodules[x].submodule_id)} defaultChecked={create === true}/></td>
              <td className="text-center"><input type="checkbox" name={nr} value={read} onChange={(e) => this.handleChange(e, module_id, submodules[x].submodule_id)} defaultChecked={read === true}/></td>
              <td className="text-center"><input type="checkbox" name={nu} value={update} onChange={(e) => this.handleChange(e, module_id, submodules[x].submodule_id)} defaultChecked={update === true}/></td>
              <td className="text-center"><input type="checkbox" name={nd} value={del} onChange={(e) => this.handleChange(e, module_id, submodules[x].submodule_id)} defaultChecked={del === true}/></td>
            </tr>
          )
        }
        return res;
      }
    }

    find_submodules_by_id( module_id, submodule_id ){
      let mod = this.state.modules_access;

      if( typeof mod == 'object' && mod != null ){
        let res_mod = mod.find(obj => obj.module_id == module_id);

        if( typeof res_mod == 'object' ){
          let submod = res_mod.submodules;
          let res_submod = submod.find(obj => obj.submodules_id == submodule_id);

          if( typeof res_submod == 'object' ){
            return res_submod;
          }else{
            return null;
          }


        }else{
          return null;
        }
      }

    }



    render() {
        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <Link className="breadcrumb-item" to="/admin/acl/group/">Group</Link>
                            <li className="breadcrumb-item active">Detail</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                          <h4 className="display-4 primary-color text-center">Detail</h4>
                          <br />


                            <fieldset>
                              <legend>Group</legend>
                              <div className="row">
                                {/** Group Name **/}
                                 <div className="col-md-6">
                                   <div className="form-group">
                                     <label>Nama Group</label>
                                     <input type="text" className="form-control" value={this.state.group_name} disabled/>
                                   </div>
                                 </div>

                                 {/** Group Desc **/}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Deskripsi</label>
                                      <input type="text" className="form-control" value={this.state.description} disabled/>
                                    </div>
                                  </div>

                              </div>
                            </fieldset>

                            <fieldset>
                              <legend>Hak Akses</legend>

                              <form onSubmit={this.handleSubmit} id="formAccess">
                              <div className="row">
                                <table className="table-group-access">
                                <thead>
                                  <tr>
                                    <th colSpan="2">Module</th>
                                    <th>Create</th>
                                    <th>Read</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.module_list()}
                                </tbody>
                                </table>
                              </div>

                              </form>
                            </fieldset>
                            <Link className="btn secondary-button" to="/admin/acl/group/">Kembali</Link>


                        </div>
                    </div>

                </div>

            </div>
        );
    }

}
