import React from 'react';
import {Link} from "react-router-dom";
import axios, {put} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          id: this.props.match.params.id,
          nik:'',
          username:'',
          nama:'',
          tempat_lahir:'',
          tanggal_lahir:'',
          jenis_kelamin:'',
          jabatan:'',
          alamat:'',
          phone:'',
          email: '',
          sk:'',
          is_verifikator: "",
          wilayah_tugas:'',
          organisasi_id: '',
          new_password:'',
          new_password2: '',
          group_id:'',
          group_list: '',
          org_list: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Users";

        this.get_user()
        this.get_group_list()
        this.get_org_list()

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    onChangeFile(e) {
      this.setState({sk:e.target.files[0]})
    }

    tanggal_lahir = date => {
      this.setState({
        tanggal_lahir: date
      });
    };

    get_user(){
      document.getElementById("loader").style.display = "inline";
      let header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };

      axios.get(api_url+"acl/users/get_users/"+this.state.id, header).then(
          response => {

            this.setState(
              {
                nik: response.data.data.nik,
                username: response.data.data.username,
                email: response.data.data.email,
                nama: response.data.data.nama,
                tempat_lahir: response.data.data.tempat_lahir,
                tanggal_lahir: new Date(response.data.data.tanggal_lahir),
                alamat: response.data.data.alamat,
                jabatan: response.data.data.jabatan,
                phone: response.data.data.phone,
                organisasi_id: response.data.data.opd_id,
                is_verifikator:(response.data.data.is_verifikator == true )?true:false,
                group_id: response.data.data.group_id,
                sk: response.data.data.sk
              }, () =>{
                document.getElementById("loader").style.display = "none";
              }
            );
        },
        error => {
          alert(error)
        }

      );
    }

    handleSubmit(event) {
        event.preventDefault();
        document.getElementById("loader").style.display = "inline";

        let formData = new FormData();
        formData.append('nik',this.state.nik);
        formData.append('username',this.state.username);
        formData.append('email',this.state.email);
        formData.append('nama',this.state.nama);
        formData.append('tempat_lahir',this.state.tempat_lahir);
        formData.append('tanggal_lahir',this.state.tanggal_lahir);
        formData.append('jenis_kelamin',this.state.jenis_kelamin);
        formData.append('jabatan',this.state.jabatan);
        formData.append('alamat',this.state.alamat);
        formData.append('phone',this.state.phone);
        formData.append('sk',this.state.sk);
        formData.append('is_verifikator',this.state.is_verifikator);
        formData.append('opd_id',this.state.organisasi_id);
        if( this.state.new_password ){
          formData.append('password',this.state.new_password);
        }

        formData.append('group_id',this.state.group_id);

        let header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        put(api_url+'acl/users/edit/'+this.state.id, formData, header).then((data)=>{
            if( typeof data == 'object' ){
              if( data.data.status == 'success' ){

                notification.open({
                  message: 'Berhasil',
                  description:'Data telah disimpan ke database!',
                });

                this.setState({loading: false});

                this.props.history.push("/admin/acl/users/list");

              }else{
                notification.open({
                    message: 'Error',
                    description: data.data.data,
                    icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                  });
                  this.setState({loading: false});
              }
            }
        })

        document.getElementById("loader").style.display = "none";

      }

    get_wilayah_tugas_list(){
      axios.get(api_url+"acl/groups/get_groups").then(
          response => {
            this.setState({ group_list: response.data.data});
        },
        error => {
          alert(error)
        }

      );
    }

   select_option_for_wilayah_tugas(){
     if( this.state.wilayah_tugas_list !== undefined && typeof this.state.wilayah_tugas_list == "object"){
       if( this.state.wilayah_tugas_list.length > 0 ){
         const res = (
             this.state.wilayah_tugas_list.map(( value ) =>
               <option value={value._id} key={ Math.random() } selected= {(value._id == this.state._id)?true:false}>{value.organisasi}</option>
             )
         );
         return res;
       }
     }
   }

   get_org_list(){
     axios.get(api_url+"organisasi/list_skpa").then(
         response => {
           this.setState({ org_list: response.data.data});
       },
       error => {
         alert(error)
       }

     );
   }

   select_option_for_org(){
     if( this.state.org_list !== undefined && typeof this.state.org_list == "object"){
       if( this.state.org_list.length > 0 ){
         const res = (
             this.state.org_list.map(( value ) =>
               <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.organisasi_id)?true:false}>{value.nama_opd}</option>
             )
         );

         return res;
       }
     }
   }

     get_group_list(){
       axios.get(api_url+"acl/groups/get_groups").then(
           response => {
             this.setState({ group_list: response.data.data});
         },
         error => {
           alert(error)
         }

       );
     }

    select_option_for_group(){
      if( this.state.group_list !== undefined && typeof this.state.group_list == "object"){
        if( this.state.group_list.length > 0 ){
          const res = (
              this.state.group_list.map(( value ) =>
                <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.group_id)?true:false}>{value.group_name}</option>
              )
          );
          return res;
        }
      }
    }

    render() {
        let file_sk = [];
        if( this.state.sk ){
          file_sk.push(<a href={api_url+this.state.sk} target="_blank" className="btn btn-danger"><i className="fas fa-file-pdf"></i> Lihat SK</a>);
        }


        return (
            <div>
                <div className="col-12 grid-margin">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <Link className="breadcrumb-item" to="/admin/acl/users/list">Users</Link>
                            <li className="breadcrumb-item active">Edit</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


				{/** NIK **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>NIK<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="NIK" name="nik" value={this.state.nik} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Username **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Username<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Username" name="username" value={this.state.username} onChange={this.handleChange} required/>
                  </div>
                </div>

        {/** Email **/}
        <div className="col-md-6">
          <div className="form-group">
            <label>Email<span className="text-danger">*</span></label>
            <input type="email" className="form-control" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChange} required/>
          </div>
        </div>


				{/** Nama **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                  </div>
                </div>


        				{/** Tempat Lahir **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Tempat Lahir</label>
                    <input type="text" className="form-control" placeholder="Tempat Lahir" name="tempat_lahir" value={this.state.tempat_lahir} onChange={this.handleChange} />
                  </div>
                </div>


        				{/** Tanggal Lahir **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Tanggal Lahir</label>
                    <div className="datepicker-container">
                      <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal_lahir} onChange={date => this.tanggal_lahir(date)} />
                    </div>
                  </div>
                </div>

                {/** Alamat **/}
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Alamat</label>
                    <input type="text" className="form-control" placeholder="Alamat" name="alamat" value={this.state.alamat} onChange={this.handleChange} />
                  </div>
                </div>


        				{/** Jabatan **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Jabatan<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Jabatan" name="jabatan" value={this.state.jabatan} onChange={this.handleChange} required/>
                  </div>
                </div>


        				{/** Nomor Telepon **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nomor Telepon</label>
                    <input type="text" className="form-control" placeholder="Nomor Telepon" name="phone" value={this.state.phone} onChange={this.handleChange} />
                  </div>
                </div>


                {/** Organisasi **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Organisasi<span className="text-danger">*</span></label>
                    <select name="organisasi_id" className="form-control" onChange={this.handleChange} required>
                    <option value="" key={ Math.random() }>Pilih</option>
                    { this.select_option_for_org() }
                    </select>
                  </div>
                </div>


        				{/** Group **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Grup<span className="text-danger">*</span></label>
                    <select name="group_id" className="form-control" onChange={this.handleChange} required>
                    <option value="" key={ Math.random() }>Pilih</option>
                    { this.select_option_for_group() }
                    </select>
                  </div>
                </div>

                {/** Password **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Ganti Password</label>
                    <input type="password" className="form-control" placeholder="Password" name="new_password" value={this.state.new_password} onChange={this.handleChange}/>
                  </div>
                </div>

                {/** Password 2 **/}
        				<div className="col-md-6">
                  <div className="form-group">
                    <label>Verifikasi Password</label>
                    <input type="password" className="form-control" placeholder="Password" name="new_password2" value={this.state.new_password2} onChange={this.handleChange}/>
                  </div>
                </div>

                {/** File  **/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>SK</label><br/>
                    {file_sk}
                  </div>
                </div>

                {/** Upload File  **/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Ulang SK</label><br/>
                    <input type="file" className="form-control-file" onChange={this.onChangeFile} />
                    <small>Upload file dalam format pdf, ukuran maksimal 1 MB</small>
                  </div>
                </div>

                {/* Input Verifikator */}
                <div className="col-md-6">
                  <div className="form-group">
                    <div class="form-check">

                      <input type="checkbox" name="is_verifikator" value={this.state.is_verifikator} onChange={this.handleChange} className="form-check-input" checked={this.state.is_verifikator} />
                      <label class="form-check-label">Akun Verifikator</label>
                    </div>
                  </div>
                </div>

        			{/*##form_input##*/}

                                </div>
                                <br/>
                                <button stype="submit" className="btn primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
