import React from 'react';
import { Link } from "react-router-dom";
import Atable from "../../lib/Atable";
import Auth from "../../helper/Authentication";
import axios from "axios";
import { message, notification, Icon, Modal, Tooltip, Skeleton } from 'antd';

const confirm = Modal.confirm;

const api_url = process.env.REACT_APP_API_URL;


export default class Trash extends React.Component {
    constructor(props) {
        super(props);

        Auth.is_access( window.location.pathname );

        this.state = {
            total_record  : 0,
            total_filter  : 0,
            limit         : 10,
            order         : "ASC",
            order_column  : 0,
            active_page   : 0,
            search        : "",
            table_data    : "",
            loading       : true
        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount(){
        document.title = process.env.REACT_APP_APP_NAME+"::Kawasan Hutan";
        this.load_data();
    }

    head(){
        return ["Kode", "Nama", "Status", /*##header_data##/*/ ""];
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            { [name]: value }, () => {
                this.load_data();
            })

    }

    load_data(){
        var form_data = {
            params: { search: this.state.search, limit: this.state.limit, offset: this.state.active_page, order: this.state.order, order_column: this.state.order_column, status: "trash" },
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.get(api_url+"kawasan_hutan/list", form_data).then(
            response => {
                this.setState({
                    table_data: response.data.data,
                    total_record: response.data.recordsTotal,
                    total_filter: response.data.recordsFiltered,
                    loading: false
                });
            },
            error => {
                alert(error)
            }

        );
    }

    generate_rows(){
        const data = this.state.table_data;

        if( typeof data == "object" && data !== null){
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
                res = data.map((value, index) => {
                  let status = [];
                  if( value.status == "draft" ){
                    status.push(<span className="badge badge-warning">Draft</span>)
                  }else if( value.status == "publish" ){
                    status.push(<span className="badge badge-primary">Publish</span>)
                  }else if( value.status == "trash" ){
                    status.push(<span className="badge badge-danger">Terhapus</span>)
                  }
                  let kab = ( value.kabupaten )?value.kabupaten[0].kabupaten:"";
                  return(
                    <tr key={ Math.random() }>
                      <td>{value.kode}</td>
                      <td>{value.nama}</td>
                      <td>{status}</td>
                      {/*##table_rows##*/}

                        <td className="text-right" width="50">

                            <Tooltip title="Kembalikan">
                                <button onClick={() => this.restore(value._id)} className="btn primary-button btn-sm">
                                    <i className="fas fa-trash-restore"></i>
                                </button>
                            </Tooltip>

                            <Tooltip title="Hapus Permanen">
                                <button onClick={() => this.delete(value._id)} className="btn btn-danger btn-sm">
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </Tooltip>

                        </td>
                    </tr>
                  )
                }
            );

            return res;
        }
    }

    restore(id){
        const e     = this;

        const header = {
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        confirm({
            title: "Apakah anda ingin mengembalikan data ini?",
            content: "Data akan dikembalikan dengan status draft",
            onOk() {
              let form_data =
              {
                status: "draft"
              };
                axios.post(api_url+"kawasan_hutan/publish/"+id, form_data, header)
                    .then(
                        response => {
                            notification.open({
                                message: 'Berhasil',
                                description:'Data telah berhasil dihapus!'
                            });
                            return e.componentDidMount()
                        },
                        error => {
                            alert(error)
                        }
                    )

                ;
            }
        });
    }

    delete(id){
        const e     = this;

        const header = {
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        confirm({
            title: "Apakah anda ingin menghapus data?",
            content: "Data akan terhapus secara permanen dan tidak dapat dikembalikan lagi",
            onOk() {
                axios.delete(api_url+"kawasan_hutan/delete/"+id+"?status=permanent", header)
                    .then(
                        response => {
                            notification.open({
                                message: 'Berhasil',
                                description:'Data telah berhasil dihapus!'
                            });
                            return e.componentDidMount()
                        },
                        error => {
                            alert(error)
                        }
                    )

                ;
            }
        });
    }

    render() {
        return (
            <div>
                {/* Breadcrumb */}
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <Link className="breadcrumb-item" to="/admin/kawasan_hutan/list">Kawasan Hutan</Link>
                            <li className="breadcrumb-item active">Tempat Sampah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tempat Sampah Kawasan Hutan</h4>
                            <br />


                            <Link className="fixed-button" to="/admin/kawasan_hutan/list">
                                <span className="fixed-img"><i className="fas fa-arrow-circle-left fa-2x"></i></span>
                                <span className="fixed-button-info">Kembali</span>
                            </Link>

                            <hr className="divider soft" />

                            <Skeleton loading={this.state.loading} active>
                                <Atable
                                    head={ this.head() }
                                    data={ this.generate_rows() }
                                    total_filter={ this.state.total_filter }
                                    total_record={ this.state.total_record }
                                    current_page={this.state.active_page}
                                    search={this.state.search}
                                    handleChange={this.handleChange}
                                    limit={this.state.limit}
                                />
                            </Skeleton>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
