import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Atable from "../../../lib/Atable";
import { message, notification, Icon, Modal, Tooltip, Skeleton } from 'antd';

const api_url = process.env.REACT_APP_API_URL;
const confirm = Modal.confirm;

export default class Detail_module extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      module_id: this.props.match.params.id,
      module_name: '',
      module_url: '',
      module_icon: '',
      is_active: '',
      sub_module_name: '',
      sub_module_url: '',

      total_record  : "",
      total_filter  : "",
      limit         : 10,
      active_page   : 1,
      search        : "",
      table_data    : "",

      loading       : true,
      loading_submodule: true
    };

    this.handleChange   = this.handleChange.bind(this);
    this.handleSubmit   = this.handleSubmit.bind(this);
    this.hapusModul     = this.hapusModul.bind(this);
    // this.hapusSubmodul  = this.hapusSubmodul.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Modul";
     this.get_module();
     this.load_submodule_data();
  }

  get_module(){
    var header = {
      headers: {
        'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.get( api_url+'acl/modules/get_modules/'+this.state.module_id, header)
    .then(
      response => response.data,
      error    => { alert(error) }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){
          const is_active = data.data.is_active === 1 ? "yes":"no";
          this.setState({
            module_name: data.data.module_name,
            module_url: data.data.module_url,
            module_icon: data.data.module_icon,
            is_active: is_active,
            loading: false
          });
        }else{
          notification.open({
            message: 'API Error',
            description: data.data,
            icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
          });
        }
      }

    })
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });

  }

  reset_input(){
    this.setState({
      sub_module_name: '',
      sub_module_url: ''
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    message.loading('Loading...', 2.5);

    var form_data =
    {
      module_id: this.state.module_id,
      submodule_name: this.state.sub_module_name,
      submodule_url: this.state.sub_module_url
    };

    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.post( api_url+'acl/modules/'+this.state.module_id+'/add_submodule', form_data, header
    ).then(response => response.data)
    .then((data) => {

      if( data['status'] === 'success' ){

        notification.open({
          message: 'Berhasil',
          description:'Data telah disimpan ke database!',
        });

        this.reset_input();
        message.destroy();
        this.load_submodule_data();
      }else{
        message.error( data['data'] );
      }

    })
  }

  head_submodule(){
    return ["Nama Sub Modul", "URL", "Aksi"];
  }

  load_submodule_data(){
    var form_data = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.get(api_url+"acl/modules/"+this.state.module_id+"/get_submodules", form_data)
    .then(
      response => {
       this.setState({
         table_data: response.data.data,
         total_record: response.data.recordsTotal,
         total_filter: response.data.recordsFiltered,
         loading_submodule: false
       });

     },
     error => {
       alert(error)
     }
   );

  }

  generate_rows(){
    const data = this.state.table_data;
    if( typeof data == "object" && data.length > 0 ){
        const res = data.map((value) =>
            <tr key={ Math.random() }>
              <td>{value.submodule_name}</td>
              <td>{value.submodule_url}</td>
              <td>
              <Tooltip title="Edit sub modul">
                <Link className="btn secondary-button btn-sm" to={`/admin/acl/modules/edit_submodule/${this.state.module_id}/${value.submodule_id}`}>
                  <i className="fas fa-edit"></i>
                </Link>
              </Tooltip>
              &nbsp;
              <Tooltip title="Hapus sub modul">
              <button onClick={() => this.hapusSubmodul(value.submodule_id, value.submodule_name)} className="btn btn-danger btn-sm" title="Hapus Sub Modul">
                <i className="fas fa-trash"></i>
              </button>
              </Tooltip>


              </td>
            </tr>
        );
        return res;
    }else{
      const res = <tr key={ Math.random() }><td>Tidak ada data</td></tr>;
      return res;
    }
  }

  hapusModul(event) {
    const module_id = this.state.module_id;
    const redirect  = this.props.history;

    const header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };
    confirm({
      title: 'Apakah anda ingin menghapus modul ini?',
      content: 'Sub modul pada modul ini akan ikut terhapus!',
      onOk() {
        axios.delete(api_url+"acl/modules/delete_module/"+module_id, header)
         .then(
           response => {
             notification.open({
               message: 'Berhasil',
               description:'Data telah berhasil dihapus!'
             });
             return redirect.push("/admin/acl/modules")
          },
          error => {
            alert(error)
          }
        );
      },
      onCancel() {},
    });
  }

  hapusSubmodul(id, name){
    const submodule_id    = id;
    const module_id       = this.state.module_id;
    // const redirect        = this.props.history;
    const e               = this;

    const header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };
    confirm({
      title: "Apakah anda ingin menghapus sub modul?",
      content: "Nama sub modul: "+name,
      onOk() {
        axios.delete(api_url+"acl/modules/"+module_id+"/delete_submodule/"+submodule_id, header)
         .then(
           response => {
             notification.open({
               message: 'Berhasil',
               description:'Data telah berhasil dihapus!'
             });
             return e.componentDidMount()
          },
          error => {
            alert(error)
          }
        )

        ;
      }
    });
  }

  render() {
    return (
      <div>
      <div className="col-12 grid-margin">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item primary-color">ACL</li>
            <Link className="breadcrumb-item" to="/admin/acl/modules">Modul</Link>
            <li className="breadcrumb-item active">Detail Modul</li>
          </ol>
        </nav>
      </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">

            <div className="row">
              <div className="col-md-6">
                <h4 className="display-4 primary-color">Detail Modul</h4>

                <Skeleton loading={this.state.loading} active>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nama Modul<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.module_name} readOnly/>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>URL Modul<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.module_url} readOnly/>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Ikon Modul<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.module_icon} readOnly/>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Status<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.is_active === "yes" ? "Aktif" : "Tidak Aktif"} readOnly/>
                    </div>
                  </div>

                  <Link className="btn secondary-button" to={`/admin/acl/modules/edit_module/${this.state.module_id}`}>
                    <i className="fas fa-edit"></i> Edit Modul
                  </Link>
                  &nbsp;
                  <button onClick={this.hapusModul} className="btn btn-danger">
                  <i className="fas fa-trash"></i> Hapus Modul
                  </button>
                </Skeleton>

              </div>

              <div className="col-md-6">
                <h4 className="display-4 primary-color">Tambah Sub Modul</h4>

                <form onSubmit={this.handleSubmit}>
                  {/* Input Nama Sub Modul */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nama Sub Modul<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Nama Sub Modul" name="sub_module_name" value={this.state.sub_module_name} onChange={this.handleChange} required/>
                    </div>
                  </div>

                  {/* Input Sub Modul URL */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>URL Sub Modul<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="URL Sub Modul" name="sub_module_url" value={this.state.sub_module_url} onChange={this.handleChange} required/>
                    </div>
                  </div>

                  <button stype="submit" className="btn btn-full primary-button">Tambah</button>
                </form>

              </div>

              {/* Submodule list */}
              <div className="col-md-12">
                <hr className="divider soft"/>
                <h4 className="display-4 primary-color">Daftar Sub Modul</h4>

                <Skeleton loading={this.state.loading_submodule} active>
                  <Atable
                    head={ this.head_submodule() }
                    data={ this.generate_rows() }
                    total_filter={ this.state.total_filter }
                    total_record={ this.state.total_record }
                    current_page={this.state.active_page}
                    search={this.state.search}
                    handleChange={this.handleChange}
                    limit={this.state.limit}
                  />
                </Skeleton>

              </div>

            </div>

            </div>
          </div>
        </div>

      </div>
    );
  }

}
