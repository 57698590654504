import React from 'react';
import { Link } from "react-router-dom";
import Atable from "../../../lib/Atable";
import Auth from "../../../helper/Authentication";
import axios from "axios";
import { message, notification, Icon, Modal, Tooltip, Skeleton } from 'antd';

const confirm = Modal.confirm;

const api_url = process.env.REACT_APP_API_URL;


export default class List extends React.Component {
    constructor(props) {
        super(props);

        Auth.is_access( window.location.pathname );

        this.state = {
            total_record  : 0,
            total_filter  : 0,
            limit         : 10,
            order         : "ASC",
            order_column  : 0,
            active_page   : 0,
            search        : "",
            table_data    : "",
            loading       : true
        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount(){
        document.title = process.env.REACT_APP_APP_NAME+"::Batas Kabupaten";
        this.load_data();
    }

    head(){
        return ["Kabupaten/Kota", "Nomor Berita Acara", "Luas (Meter)", "Status", /*##header_data##/*/ ""];
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            { [name]: value }, () => {
                this.load_data();
            })

    }

    load_data(){
        var form_data = {
            params: { search: this.state.search, limit: this.state.limit, offset: this.state.active_page, order: this.state.order, order_column: this.state.order_column },
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.get(api_url+"batas_wilayah/batas_kabupaten/list", form_data).then(
            response => {
                this.setState({
                    table_data: response.data.data,
                    total_record: response.data.recordsTotal,
                    total_filter: response.data.recordsFiltered,
                    loading: false
                });
            },
            error => {
                alert(error)
            }

        );
    }

    generate_rows(){
        const data = this.state.table_data;

        if( typeof data == "object" && data !== null){
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
                res = data.map((value, index) => {
                  let status = [];
                  if( value.status == "draft" ){
                    status.push(<span className="badge badge-warning">Draft</span>)
                  }else if( value.status == "publish" ){
                    status.push(<span className="badge badge-primary">Publish</span>)
                  }
                  return(
                    <tr key={ Math.random() }>

                        <td>{value.kabupaten[0].kabupaten}</td>
                        <td>{value.nomor_izin}</td>
                        <td>{new Intl.NumberFormat('id-ID').format(value.luas)}</td>
                        <td>{status}</td>
                        {/*##table_rows##*/}

                        <td className="text-right" width="50">

                            <Tooltip title="Detail">
                                <Link className="btn primary-button btn-sm" to={`/admin/batas_wilayah/batas_kabupaten/detail/${value._id}`}>
                                    <i className="fas fa-eye"></i>
                                </Link>
                            </Tooltip>

                            <Tooltip title="Edit">
                                <Link className="btn secondary-button btn-sm" to={`/admin/batas_wilayah/batas_kabupaten/edit/${value._id}`}>
                                    <i className="fas fa-edit"></i>
                                </Link>
                            </Tooltip>

                            <Tooltip title="Hapus">
                                <button onClick={() => this.delete(value._id)} className="btn btn-danger btn-sm" title="Hapus">
                                    <i className="fas fa-trash"></i>
                                </button>
                            </Tooltip>

                        </td>
                    </tr>
                  )
                }
            );

            return res;
        }
    }

    delete(id){
        const e     = this;

        const header = {
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        confirm({
            title: "Apakah anda ingin menghapus data?",
            content: "Data terhapus dapat dikembalikan melalui tempat sampah",
            onOk() {
                axios.delete(api_url+"batas_wilayah/batas_kabupaten/delete/"+id, header)
                    .then(
                        response => {
                            notification.open({
                                message: 'Berhasil',
                                description:'Data telah berhasil dihapus!'
                            });
                            return e.componentDidMount()
                        },
                        error => {
                            alert(error)
                        }
                    )

                ;
            }
        });
    }

    render() {
        return (
            <div>
                {/* Breadcrumb */}
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Batas Wilayah</li>
                            <li className="breadcrumb-item active">Batas Kabupaten/Kota</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Batas Kabupaten/Kota</h4>
                            <br />
                            <Link className="btn primary-button" to="/admin/batas_wilayah/batas_kabupaten/add">
                                Tambah
                            </Link>


                            <Link className="fixed-button" to="/admin/batas_wilayah/batas_kabupaten/trash">
                                <span className="fixed-img"><i className="fas fa-trash fa-2x"></i></span>
                                <span className="fixed-button-info">Tempat Sampah</span>
                            </Link>

                            <hr className="divider soft" />

                            <Skeleton loading={this.state.loading} active>
                                <Atable
                                    head={ this.head() }
                                    data={ this.generate_rows() }
                                    total_filter={ this.state.total_filter }
                                    total_record={ this.state.total_record }
                                    current_page={this.state.active_page}
                                    search={this.state.search}
                                    handleChange={this.handleChange}
                                    limit={this.state.limit}
                                />
                            </Skeleton>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
