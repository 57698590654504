import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {

  componentDidMount(){
    let id = document.getElementById("sidebarCollapse");
    id.onclick = function(){
      document.getElementById("sidebar").classList.toggle("active");
      id.classList.toggle("active");
    }
  }

  fullScreen() {
      let elem = document.body;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
  }

  render() {
    return (
      <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex align-items-top justify-content-center">
          <Link className="navbar-brand admin brand-logo" to="/admin/dashboard">
            {process.env.REACT_APP_APP_NAME}
          </Link>

          <Link className="navbar-brand brand-logo-mini" to="/admin/dashboard">
            {process.env.REACT_APP_APP_NAME}
          </Link>

        </div>

        <div className="navbar-menu-wrapper d-flex align-items-center">

          <button type="button" id="sidebarCollapse" className="navbar-btn">
              <span></span>
              <span></span>
              <span></span>
          </button>

          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item">
              <a href="#" onClick={() => this.fullScreen()} className="nav-link"><i className="fas fa-compress soft-color"></i></a>
            </li>

            <li className="nav-item dropdown d-none d-xl-inline-block">
              <a href="#userDropdown" className="nav-link dropdown-toggle" id="UserDropdown"  data-toggle="dropdown" aria-expanded="false">
                <span className="profile-text">Halo, {localStorage.getItem('nama')}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                <a href="#dropItem" className="dropdown-item p-0">
                  <div className="d-flex border-bottom mt-4">

                  </div>
                </a>

                <Link className="dropdown-item" to="/admin/edit_profil">
                  Profil Saya
                </Link>

                <Link className="dropdown-item" to="/logout">
                  Logout
                </Link>

              </div>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>

  );
  }
}

export default Header;
