import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import moment from 'moment';
import 'moment/locale/id';
import Auth from "../../../helper/Authentication";
import { Map, GeoJSON, TileLayer, Marker, Popup } from 'react-leaflet';
const confirm = Modal.confirm;
const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = {
            id: this.props.match.params.id,
            provinsi_id: null,
            nomor_izin:'',
            tanggal_berita_acara: new Date(),
            file_sertifikat:'',
            status: '',
            koordinat_opt: "1",
            entri_koordinat_error: '',
            list_koordinat: null,
            file_geojson: null,
            file_geojson_new: null,
            lat: 5.5530676,
            long: 95.2938525,
            zoom: 7

        };

    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Batas Provinsi";
        this.get_data();

        // loader
        document.getElementById("loader").style.display = "inline";

    }

    get_data(){
        var header = {
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'batas_wilayah/batas_provinsi/get/'+this.state.id, header)
            .then(
                response => response.data,
                error => { alert( error ) }
            )
            .then((data) => {

                if( typeof data == 'object' ){
                    if( data.status === 'success' ){
                        this.setState({
                            provinsi_id: data.data.provinsi[0].provinsi_id,
                            provinsi: data.data.provinsi[0].provinsi,
                            tanggal_berita_acara: data.data.tanggal_berita_acara,
                            file_sertifikat: data.data.file_sertifikat,
                            nomor_izin: data.data.nomor_izin,
                            nomor_pilar: data.data.nomor_pilar,
                            warna_palette: data.data.warna_palette,
                            status: data.data.status,
                            file_geojson: data.data.file_geojson,
                            geojson: data.data.geojson
                        }, () =>{
                            // hide loader
                            document.getElementById("loader").style.display = "none";
                            if( this.state.file_geojson ){
                              this.load_json_from_url(api_url+this.state.file_geojson)
                            }
                        });
                    }else{
                        message.error( data.data );
                    }

                }
            })
    }

    load_json_from_url( url ){
      document.getElementById("loader").style.display = "inline";
      axios.get( url )
      .then(
        response => response.data,
        error => { alert( error ) }
      )
      .then((data) => {
        this.setState({
          geojson_data: data,
          // lat   : data.features[0].geometry.coordinates[0][0][1],
          // long  : data.features[0].geometry.coordinates[0][0][0]
        }, () =>{
          // hide loader
          document.getElementById("loader").style.display = "none";
        });
      })
    }

    geoJSONStyle() {
     return {
       color: '#e6b901',
       weight: 1,
       fillOpacity: 0.5,
       fillColor: '#00c823',
     }
   }

   onEachFeature(feature: Object, layer: Object) {
     const popupContent = ` <Popup><br />${feature.properties.Name}</pre></Popup>`
     layer.bindPopup(popupContent)
   }

   publish(status){
     const e     = this;

     const header = {
       headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
     };
     confirm({
       title: "Apakah anda ingin mempublish data?",
       content: "",
       onOk() {
         document.getElementById("loader").style.display = "inline";

         let form_data =
         {
           status: status
         };

         var header = {
           headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
         };

         axios.post( api_url+'batas_wilayah/batas_provinsi/publish/'+e.state.id, form_data, header
         ).then(
           response => response.data,
           error => {
             alert(error)
           }
         )
         .then((data) => {
           if( data.status === 'success' ){

             notification.open({
               message: 'Berhasil',
               description:'Data telah disimpan ke database!',
             });

             e.componentDidMount()

           }else{
             notification.open({
                 message: 'Error',
                 description: data.data,
                 icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
               });
           }

         })

       }
     });
   }


    render() {
        // file sertifikat
        let file_sertifikat = [];
        if( this.state.file_sertifikat != null ){
            file_sertifikat.push(<a href={api_url+this.state.file_sertifikat} target="_BLANK" className="btn primary-button">Lihat File</a>)
        }else{
            file_sertifikat.push(<span className="text-danger">Tidak ada file</span>)
        }

        let file_geojson = null;
        if( this.state.file_geojson ){
            file_geojson = api_url+this.state.file_geojson;
        }

        let color_palette = this.state.warna_palette;

        let status = [];
        let publish_btn = [];
        if(this.state.status == "draft"){
          status.push(<div className="badge badge-warning">DRAFT</div>)

          if( localStorage.getItem('is_verifikator') == "true" ){
            publish_btn.push(
              <button type="button" onClick={() => this.publish("publish")} className="btn primary-button btn-sm" title="Publish">
                <i className="fas fa-check"></i> Publish
              </button>
            )
          }

        }else if ( this.state.status == "publish" ) {
          status.push(<div className="badge badge-primary">PUBLISH</div>)

          if( localStorage.getItem('is_verifikator') == "true" ){
            publish_btn.push(
              <button type="button" onClick={() => this.publish("draft")} className="btn primary-button btn-sm" title="Draft">
                <i className="fas fa-check"></i> Jadikan Draft
              </button>
            )
          }

        }

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Batas Wilayah</li>
                            <Link className="breadcrumb-item" to="/admin/batas_wilayah/batas_provinsi/list">Batas Provinsi</Link>
                            <li className="breadcrumb-item active">Detail</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Detail Batas Provinsi</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>

                                <fieldset>
                                    <legend>Wilayah</legend>
                                    <div className="row">
                                        {/** Provinsi **/}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Provinsi<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" value={this.state.provinsi} disabled/>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>

                                <fieldset>
                                    <legend>Batasan</legend>
                                    <div className="row">
                                        {/** Nomor Izin **/}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Nomor Berita Acara</label>
                                                <input type="text" className="form-control" value={this.state.nomor_izin} disabled/>
                                            </div>
                                        </div>

                                        {/** Tanggal Izin **/}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Tanggal Berita Acara</label>
                                                <input type="text" className="form-control" value={moment(this.state.tanggal_berita_acara).format("D MMMM YYYY")} disabled/>
                                            </div>
                                        </div>

                                        {/** Nomor Pilar **/}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Nomor Pilar</label>
                                                <input type="text" className="form-control" value={this.state.nomor_pilar} disabled/>
                                            </div>
                                        </div>

                                        {/** Sertifikat **/}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>File PDF</label><br/>
                                                {file_sertifikat}
                                            </div>
                                        </div>

                                    </div>

                                </fieldset>

                                <fieldset>
                                    <legend>Koordinat</legend>
                                    <div className="row">
                                        <div className="col-md-12">
                                        <Map
                                          center={[this.state.lat, this.state.long]} zoom={this.state.zoom} id="leaflet-map-detail"
                                        >

                                        <TileLayer
                                          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />

                                        {this.state.geojson_data ? (
                                          <GeoJSON
                                            key={`marker-01`}
                                            data={this.state.geojson_data}
                                            style={this.geoJSONStyle}
                                            onEachFeature={this.onEachFeature}
                                          />
                                        ) :  ""}

                                        </Map>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset>
                                  <legend>Status</legend>
                                  {status}
                                </fieldset>
                                {publish_btn}

                                <Link className="btn secondary-button" to="/admin/batas_wilayah/batas_provinsi/list">Kembali</Link>

                            </form>

                        </div>
                    </div>

                </div>

            </div>
        );
    }

}
