import React from 'react';

export default class Logout extends React.Component {
  constructor(props) {
    super(props);

    // remove all
    localStorage.clear();
    this.props.history.push("/login");

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Logout"
   }


  render() {
    return (
      <div>Logout</div>
    );
  }

}
